<!--Border-->
<form [formGroup]="searchForm">
   <div class="border-b border-gray-300 pb-4">
      <!--Title-->
      <div>
         <p class="text-2xl font-bold leading-7 text-gray-900 mr-4 py-1.5">
            Search
         </p>
      </div>

      <!--Search Input-->
      <div>
         <div>
            <div class="mt-2">
               <input
                  formControlName="query"
                  type="search"
                  name="search"
                  id="search"
                  autofocus
                  autocomplete="off"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search everything..."
                  (keydown.enter)="$event.preventDefault(); search()"
               />
            </div>
         </div>
      </div>
   </div>

   <!--Main Content-->
   <div class="flex flex-row h-screen">
      <!--Facets-->
      <div class="w-1/5 border-r">
         <div>
            <!--<p class="pt-4 font-semibold leading-7 text-gray-900">-->
            <!--   Refine results-->
            <!--</p>-->

            <!--Options-->
            <fieldset formArrayName="sectionIDs">
               <div class="mt-4">
                  <p class="text-gray-700 font-semibold text-sm">Sections</p>
                  <div
                     *ngFor="let section of accessibleSections; let i = index"
                     class="relative flex items-start ml-2"
                  >
                     <div class="flex h-6 items-center">
                        <input
                           type="checkbox"
                           [formControlName]="i"
                           aria-describedby="comments-description"
                           name="comments"
                           class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                     </div>
                     <div class="ml-3 text-sm leading-6">
                        <label class="font-medium text-gray-900">{{
                           section.name
                        }}</label>
                     </div>
                  </div>
               </div>
            </fieldset>
         </div>
      </div>

      <!--Results-->
      <div class="w-full m-4 space-y-5">
         @for (res of searchResults; track $index) {
            <div
               class="divide-y divide-gray-200 space-y-2 overflow-hidden bg-white border border-gray-200 cursor-pointer"
               [routerLink]="['/case', res.caseID]"
            >
               <div class="px-4 py-2 text-gray-900 font-semibold align-middle">
                  {{ res.patientName }} ({{ res.MRN }})
               </div>
               <!--FYI: Using innerHTML preserves bolding of matched keywords while providing modest sanitization to prevent XSS-->
               <div
                  class="text-gray-900 font-normal px-4 py-4 text-base"
                  [innerHTML]="res.fragments"
               ></div>
            </div>
         }
      </div>
   </div>
</form>
