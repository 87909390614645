import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { ActionItem, MonarchCaseTab } from '../../../shared/interfaces'
import {
   BadgeTheme,
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { ActionItemFormComponent } from './action-item-form.component'

@Component({
   selector: 'app-tab-action-items',
   standalone: true,
   imports: [CommonModule, ActionItemFormComponent, SimpleListComponent],
   templateUrl: './tab-case-action-items.component.html',
})
export class TabCaseActionItems implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) allowEditing: boolean = false
   $caseActionItems: WritableSignal<ActionItem[]> = signal([])
   $actionItemFormIsDisplayed: WritableSignal<boolean> = signal(false)
   actionItemsDisplayList: SimpleList = []
   selectedActionItemID: number = 0

   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.actionItemsDisplayList = this.$caseActionItems().map(
            (x: ActionItem) => this.mapActionItemToListItem(x)
         )
      })
   }

   ngOnInit() {
      this.caseManagementService.getRelatedActionItems(this.caseID).subscribe({
         next: (value) =>
            this.$caseActionItems.set(value.filter((x) => !x.isDeleted)),
      })
   }

   mapActionItemToListItem(ai: ActionItem): SimpleListItem {
      let d = new Date(ai.dueDate)
      let listItem = {
         id: ai.actionItemID,
         title: ai.title,
         description: '',
         propertyPairs: [
            {
               key: 'CREATED_AT',
               display: 'Created',
               value: new Date(ai.createdAt).toLocaleString(),
            },
            {
               key: 'CREATED_BY',
               display: 'By',
               value: ai.createdBy,
            },
            {
               key: 'DUE_DATE',
               display: 'Due',
               value: d.toLocaleString('en'), //TODO: Make a standard date format
            },
         ],
         badges: [
            {
               title: ai.status,
               theme: this.mapActionItemStatusToBadgeColor(ai.status),
            },
         ],
      }

      // Assignees
      if (ai.assigneesDisplay) {
         listItem.propertyPairs.push({
            key: 'ASSIGNEES',
            display: 'Assigned To',
            value: ai.assigneesDisplay,
         })
      }

      d = new Date(ai.closeDate)
      if (ai.isClosed) {
         listItem.propertyPairs.push({
            key: 'CLOSE_DATE',
            display: 'Closed On',
            value: d.toLocaleDateString('en'),
         })
      }

      // If no assignees, push a warning
      if (!ai.assigneesDisplay) {
         listItem.badges.push({
            title: 'Not Assigned',
            theme: BadgeTheme.ORANGE,
         })
      }

      // Overdue badge
      if (ai.isOverdue) {
         listItem.badges.push({
            title: 'Overdue',
            theme: BadgeTheme.RED,
         })
      }
      return listItem
   }

   mapActionItemStatusToBadgeColor(status: string): BadgeTheme {
      switch (status) {
         case 'In Progress':
            return BadgeTheme.YELLOW
         case 'Complete':
            return BadgeTheme.GREEN
         case 'On Hold':
            return BadgeTheme.GRAY
         case 'Canceled':
            return BadgeTheme.GRAY
         case 'Overdue':
            return BadgeTheme.RED
      }
      return BadgeTheme.GRAY
   }
}
