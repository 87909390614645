<app-action-tab [$formIsDisplayed]="$modalUserFormDisplayed">
   <app-button
      [label]="'New User'"
      (click)="$modalUserFormDisplayed.set(true)"
      class="ngc-button-region"
   ></app-button>
   <app-user-security-report
      class="ngc-table-region"
   ></app-user-security-report>

   <!--<app-interactive-table-->
   <!--   [columnConfiguration]="cplumns"-->
   <!--   [tableData]="users"-->
   <!--   [isProcessing]="$isProcessing"-->
   <!--   class="ngc-table-region"-->
   <!--&gt;</app-interactive-table>-->
   <app-user-form
      [$formIsDisplayed]="$modalUserFormDisplayed"
      class="ngc-form-region"
   ></app-user-form>
</app-action-tab>
