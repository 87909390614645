import { Component, OnInit } from '@angular/core'
import {
   AbstractControl,
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   ValidationErrors,
   ValidatorFn,
   Validators,
} from '@angular/forms'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { NgIf } from '@angular/common'
import { UserService } from '../../services/user.service'
import {
   NotificationService,
   NotificationType,
} from '../../services/notification.service'

@Component({
   selector: 'app-user-activation',
   standalone: true,
   imports: [ReactiveFormsModule, NgIf],
   templateUrl: './user-activation.component.html',
})
export class UserActivationComponent implements OnInit {
   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private notificationService: NotificationService
   ) {}

   // TODO: Needs password compliance validator, password match validator
   accountActivationForm = new FormGroup(
      {
         activationToken: new FormControl(''),
         email: new FormControl(''),
         newPassword: new FormControl('', Validators.minLength(12)),
         confirmPassword: new FormControl('', Validators.minLength(12)),
      },
      [passwordMatchValidator]
   )

   ngOnInit() {
      this.accountActivationForm.controls.activationToken.setValue(
         this.route.snapshot.queryParamMap.get('activationToken')
      )
      this.accountActivationForm.controls.activationToken.disable()

      this.accountActivationForm.controls.email.setValue(
         this.route.snapshot.queryParamMap.get('email')
      )
      this.accountActivationForm.controls.email.disable()
   }

   activateAccountWithPasswordChange() {
      this.userService
         .activateAccount({
            activationToken:
               this.accountActivationForm.controls.activationToken.getRawValue()!,
            email: this.accountActivationForm.controls.email.getRawValue()!,
            newPassword:
               this.accountActivationForm.controls.newPassword.getRawValue()!,
         })
         .subscribe({
            next: (value) => {
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully activated'
               )
               this.router.navigate(['home'])
            },
            error: (err) => {
               console.log(err)
               this.notificationService.new(
                  NotificationType.Error,
                  'There was an issue activating your account'
               )
            },
         })
   }
}

export const passwordMatchValidator: ValidatorFn = (
   control: AbstractControl
): ValidationErrors | null => {
   const formGroup = control as FormGroup
   const newPassword = formGroup.get('newPassword')
   const confirmPassword = formGroup.get('confirmPassword')

   if (!formGroup.pristine) {
      console.log('control was touched. evaluating match')
      return newPassword?.value === confirmPassword?.value
         ? null
         : { passwordsMismatch: true }
   } else {
      return null
   }
}
