import {
   Component,
   HostListener,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CaseManagementService } from '../../../services/case-management.service'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Complication, AdverseEvent } from '../../../shared/interfaces'
import { forkJoin } from 'rxjs'
import { NgFor, NgIf } from '@angular/common'
import { isNewRecord } from '../tab-failures-modes/failure-mode-form.component'
import {
   CREATED,
   NotificationService,
   NotificationType,
   UPDATED,
} from '../../../services/notification.service'
import { DangerComponent } from '../../../shared/components/button/danger/danger.component'
import { AdminService } from '../../../services/admin.service'

@Component({
   selector: 'app-adverse-event-form',
   templateUrl: './adverse-event-form.component.html',
   standalone: true,
   imports: [NgIf, ReactiveFormsModule, NgFor, DangerComponent],
})
export class AdverseEventFormComponent implements OnInit {
   // MARK: Constructor
   constructor(
      private caseManagementService: CaseManagementService,
      private adminService: AdminService,
      private notificationService: NotificationService
   ) {}

   // MARK: Listeners
   @HostListener('window:keydown.esc', ['$event'])
   handleEscapeKey() {
      this.cancel()
   }

   @HostListener('window:keydown.enter', ['$event'])
   handleEnterKey() {
      this.submit()
   }

   // MARK: Properties
   @Input() caseID: number = 0
   @Input() selectedAdverseEventID: number = 0
   @Input() $caseAdverseEvents: WritableSignal<AdverseEvent[]> | undefined
   @Input() $formIsDisplayed: WritableSignal<boolean> = signal(false)
   dataIsLoaded: boolean = false

   adverseEventForm = new FormGroup({
      complicationCategoryID: new FormControl(),
      complicationID: new FormControl(),
      note: new FormControl(),
   })

   allAdverseEventOptions: Complication[] = []
   selectableAdverseEventOptions: {
      id: number
      name: string
      description: string
      category: string
   }[] = []

   // TODO: P3 - Hard-coded
   adverseEventCategory: { id: number; name: string }[] = [
      { id: 1, name: 'Airway' },
      { id: 2, name: 'Respiratory' },
      { id: 3, name: 'Cardiovascular' },
      { id: 4, name: 'Neurologic' },
      { id: 5, name: 'Regional' },
      { id: 6, name: 'Intraoperative and Perioperative Events' },
   ]

   // MARK Lifecycle
   ngOnInit() {
      // If editing, need to synchronize the fetch of options and the event itself
      if (!isNewRecord(this.selectedAdverseEventID)) {
         forkJoin({
            adverseEventOptions: this.adminService.getAdverseEventOptions(),
            adverseEvent: this.caseManagementService.getAdverseEvent(
               this.caseID,
               this.selectedAdverseEventID
            ),
         }).subscribe({
            next: (value) => {
               this.allAdverseEventOptions = value.adverseEventOptions
               this.adverseEventForm.patchValue(
                  {
                     complicationCategoryID:
                        value.adverseEvent.complicationCategoryID,
                     complicationID: value.adverseEvent.complicationID,
                     note: value.adverseEvent.note,
                  },
                  { emitEvent: true }
               )
               this.filterAdverseEventsByCategory(
                  value.adverseEvent.complicationCategoryID
               )
               this.adverseEventForm.controls[
                  'complicationCategoryID'
               ].disable()
               this.adverseEventForm.controls['complicationID'].disable()
            },
            complete: () => {
               this.dataIsLoaded = true
            },
         })
      } else {
         // Else we're creating and don't need to fetch the event
         this.adminService.getAdverseEventOptions().subscribe({
            next: (value) => (this.allAdverseEventOptions = value),
            complete: () => (this.dataIsLoaded = true),
         })
      }
   }

   // MARK: Methods
   submit() {
      if (isNewRecord(this.selectedAdverseEventID)) {
         this.createComplication()
      } else {
         this.updateComplication()
      }
   }

   cancel() {
      this.$formIsDisplayed.set(false)
   }

   delete() {
      this.caseManagementService
         .deleteAdverseEvent(this.caseID, this.selectedAdverseEventID)
         .subscribe({
            next: () => {
               const index = this.$caseAdverseEvents!().findIndex(
                  (adverseEvent) =>
                     adverseEvent.id === this.selectedAdverseEventID
               )
               if (index > -1) {
                  // FYI: Must return a copy with toSpliced to trigger Signal Effect()
                  this.$caseAdverseEvents!.update(() =>
                     this.$caseAdverseEvents!().toSpliced(index, 1)
                  )
               }
            },
            complete: () => {
               this.$formIsDisplayed.set(false)
               this.notificationService.new(
                  NotificationType.Info,
                  'Successfully deleted'
               )
            },
         })
   }

   setSelectedCategory(e: Event) {
      let selectedCategory = parseInt((e.target as HTMLSelectElement).value)
      this.filterAdverseEventsByCategory(selectedCategory)
   }

   filterAdverseEventsByCategory(categoryID: number) {
      this.selectableAdverseEventOptions = this.allAdverseEventOptions.filter(
         (value) => value.categoryID === categoryID
      )
   }

   createComplication() {
      this.caseManagementService
         .createAdverseEvent({
            caseID: this.caseID,
            complicationID: parseInt(
               <string>(
                  this.adverseEventForm.controls['complicationID'].getRawValue()
               )
            ),
            note: this.adverseEventForm.controls['note'].getRawValue(),
         })
         .subscribe({
            next: (value) => {
               this.$caseAdverseEvents?.update((list) => [...list, value])
            },
            complete: () => {
               this.notificationService.new(NotificationType.OK, CREATED)
               this.$formIsDisplayed.set(false)
            },
         })
   }

   updateComplication() {
      this.caseManagementService
         .updateAdverseEvent({
            caseID: this.caseID,
            caseComplicationID: this.selectedAdverseEventID,
            note: this.adverseEventForm.controls['note'].getRawValue(),
         })
         .subscribe({
            next: (updatedCaseAdverseEvent) => {
               this.$caseAdverseEvents?.update((items) =>
                  items.map((item) =>
                     item.id === this.selectedAdverseEventID
                        ? updatedCaseAdverseEvent
                        : item
                  )
               )
            },
            complete: () => {
               this.notificationService.new(NotificationType.OK, UPDATED)
               this.$formIsDisplayed.set(false)
            },
         })
   }

   protected readonly isNewRecord = isNewRecord
}
