export enum formMode {
   CREATE = 'Create',
   EDIT = 'Edit',
}

export enum formAction {
   CREATE,
   UPDATE,
   CANCEL,
   DELETE,
}

export enum WindowEvent {
   Cancel,
   Save,
   SaveAndClose,
}

export enum caseNavigationTabList {
   OVERVIEW = 'Case Overview',
   ENCOUNTERS = 'Encounters',
   PROCEDURES = 'Procedures',
   STAFF = 'Providers',
   ADVERSE_EVENTS = 'Adverse Events',
   OUTCOMES = 'Outcomes',
   DISCUSSION = 'Discussion',
   FAILURE_MODES = 'Failure Modes',
   ACTION_ITEMS = 'Action Items',
   DOCUMENTS = 'Documents',
   ROUTING = 'Routing',
   AUDIT = 'Audit Trail',
}

export enum adminNavigationTabList {
   SECTIONS = 'Sections',
   ROLES = 'Roles',
   USERS = 'Users',
   SECURITY = 'User Permissions',
   DROPDOWNS = 'Dropdowns',
   COMPLICATIONS = 'Complications',
   OUTCOMES = 'Outcomes',
   FAILURE_MODE_TAXONOMY = 'Failure Mode Taxonomy',
   LICENSE = 'License',
}
