import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
   providedIn: 'root',
})
export class NotificationService {
   constructor() {}

   notification$: EventEmitter<SimpleNotification> =
      new EventEmitter<SimpleNotification>()

   new(type: NotificationType, title: string, body?: string) {
      this.notification$.emit({ type: type, title: title, body: body })
   }
}
export interface SimpleNotification {
   type: NotificationType
   title: string
   body?: string
}

export enum NotificationType {
   OK,
   Info,
   Alert,
   Error,
}

export const CREATED: string = 'Successfully Created'
export const UPDATED: string = 'Successfully Updated'
export const ERROR: string = 'Error'
export const WARNING: string = 'Warning'
