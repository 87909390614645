import { Pipe, PipeTransform } from '@angular/core'

// ZeroDatePipe assesses whether a Date (input as a string) is the Golang zero-value date
// If it is zero-value, null is returned
@Pipe({
   name: 'zeroDatePipe',
   standalone: true,
})
export class ZeroDatePipePipe implements PipeTransform {
   transform(value: Date | string): null | string {
      const goZeroDateValueUTC = 'Mon, 01 Jan 0001 00:00:00 GMT'
      if (typeof value === 'string') {
         value = new Date(value)
      }

      if (value.toUTCString() === goZeroDateValueUTC) {
         return null
      } else {
         return value.toLocaleString()
      }
   }
}
