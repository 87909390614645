<app-action-tab>
   <app-button
      [label]="'Upload License File'"
      class="ngc-button-region"
   ></app-button>
   <app-interactive-table
      [columnConfiguration]="columns"
      [tableData]="data"
      class="ngc-table-region"
   ></app-interactive-table>
</app-action-tab>
