<app-modal [$isDisplayed]="$formIsDisplayed" title="New Linked Provider">
   <div class="flex flex-col">
      <input type="text" [(ngModel)]="searchTerm" />
      <app-interactive-table
         [columnConfiguration]="tableColumns"
         [tableData]="searchResults"
         [enableSelection]="true"
         [isProcessing]="$searchIsProcessing"
         (selectionEvent)="selectedPractitioner = $event"
      >
      </app-interactive-table>
   </div>
   <div class="ngc-footer-right">
      <button type="button" (click)="search()">Search</button>
      <button type="button" (click)="save()">Save</button>
   </div>
</app-modal>
