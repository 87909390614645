import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { catchError, throwError } from 'rxjs'
import {
   NotificationService,
   NotificationType,
} from '../services/notification.service'
import { inject } from '@angular/core'

// httpErrorInterceptor listens for any status >= 400 and triggers an end-user facing notification
// TODO: error.error.error is hard to follow. Implement an interface for the API error response
// TODO: Handle more/all errors that response.go is configured to send
export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
   const notificationService = inject(NotificationService)
   return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
         //
         let displayableError = 'Error: ' + error.error.error
         console.log('Error interceptor is evaluating error')
         switch (error.status) {
            case 0:
               notificationService.new(
                  NotificationType.Error,
                  'Error: Server unavailable. Contact your System Administrator'
               )
               break
            case 400:
               notificationService.new(NotificationType.Error, displayableError)
               break
            case 401:
               console.log('Unauthorized')
               notificationService.new(NotificationType.Error, displayableError)
               break
            default:
               if (error.status >= 500) {
                  notificationService.new(
                     NotificationType.Error,
                     'Server Error: ' + displayableError
                  )
               } else {
                  notificationService.new(
                     NotificationType.Error,
                     'Unknown error: ' + displayableError
                  )
               }
         }
         return throwError(error)
      })
   )
}
