<div
   class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
   role="menu"
   aria-orientation="vertical"
   aria-labelledby="menu-button"
   tabindex="-1"
>
   @for (optionGroup of options; track $index) {
      <div class="py-1" role="none">
         @for (
            option of optionGroup;
            track $index;
            let firstIterationInGroup = $first
         ) {
            @if (this.firstRowIsHeader && firstIterationInGroup) {
               <a
                  class="text-gray-800 font-bold block px-4 py-2 text-sm hover:bg-gray-50 border-b border-gray-300"
                  [ngClass]="{
                     'text-violet-700 font-semibold bg-gray-100':
                        option.isSelected
                  }"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-{{ $index }}"
                  >{{ option.displayValue }}</a
               >
            } @else {
               <a
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
                  [ngClass]="{
                     'text-violet-700 font-semibold bg-gray-100':
                        option.isSelected
                  }"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-{{ $index }}"
                  >{{ option.displayValue }}</a
               >
            }
         }
      </div>
   }
</div>
