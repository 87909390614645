import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'
import {
   ActionItem,
   AdverseEvent,
   CaseFailureResponse,
   Outcome,
   ReportTopic,
} from '../shared/interfaces'
import { Observable } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class ReportingService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}
   downloadReportAsExcel(report: ReportTopic) {
      let params = new HttpParams()
      params = params.append('format', 'excel')
      const request = new HttpRequest(
         'GET',
         `${this.endpoint}/report/${report.toString()}`,
         {
            responseType: 'blob',
            params: params,
         }
      )
      return this.http.request(request)
   }

   getManyActionItems(): Observable<ActionItem[]> {
      return this.http.get<ActionItem[]>(`${this.endpoint}/report/actionItem`)
   }

   getManyAdverseEvents(): Observable<AdverseEvent[]> {
      return this.http.get<AdverseEvent[]>(
         `${this.endpoint}/report/adverseEvent`
      )
   }
   getManyOutcomes(): Observable<Outcome[]> {
      return this.http.get<Outcome[]>(`${this.endpoint}/report/outcome`)
   }

   getManyFailureModes(): Observable<CaseFailureResponse[]> {
      return this.http.get<CaseFailureResponse[]>(
         `${this.endpoint}/report/failureMode`
      )
   }
}
