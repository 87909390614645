import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
   name: 'booleanPipe',
   standalone: true,
})
export class BooleanPipePipe implements PipeTransform {
   transform(value: boolean): string {
      return value ? 'Open' : 'Closed'
   }
}
