import { Injectable, isDevMode } from '@angular/core'

@Injectable({
   providedIn: 'root',
})
export class LoggingService {
   constructor() {
      console.log(
         '\n' +
            '    __  ___                            __  \n' +
            '   /  |/  /___  ____  ____ ___________/ /_ \n' +
            '  / /|_/ / __ \\/ __ \\/ __ `/ ___/ ___/ __ \\\n' +
            ' / /  / / /_/ / / / / /_/ / /  / /__/ / / /\n' +
            '/_/  /_/\\____/_/ /_/\\__,_/_/   \\___/_/ /_/ \n' +
            '                                           \n'
      )
   }

   error(msg: any) {
      console.log('ERROR: ', msg)
   }

   info(msg: any) {
      console.log('INFO: ', msg)
   }

   debug(msg: any) {
      if (isDevMode()) {
         console.log('DEBUG: ', msg)
      }
   }
}
