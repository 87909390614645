<div
   class="relative z-50"
   aria-labelledby="slide-over-title"
   role="dialog"
   aria-modal="true"
>
   <div class="fixed inset-0"></div>
   <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
         <div
            class="pointer-events-none absolute top-0 right-0 bottom-0 w-1/3 max-w-1/2"
         >
            <div
               class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
            >
               <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div class="px-4 sm:px-6">
                     <div class="flex items-start justify-between">
                        <h2
                           class="text-xl font-semibold leading-6 text-gray-900"
                           id="slide-over-title"
                        >
                           Report Configuration
                        </h2>
                        <div
                           class="ml-3 flex h-7 items-center"
                           (click)="windowEvent$.emit(WindowEvent.Cancel)"
                        >
                           <button
                              type="button"
                              class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                           >
                              <span class="absolute -inset-2.5"></span>
                              <span class="sr-only">Close panel</span>
                              <svg
                                 class="h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 aria-hidden="true"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                 />
                              </svg>
                           </button>
                        </div>
                     </div>
                     <div class="flex flex-row mt-2 justify-between">
                        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                        <p class="text-gray-700 text-sm">
                           Apply changes immediately?
                        </p>
                        <button
                           type="button"
                           class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                           role="switch"
                           aria-checked="false"
                        >
                           <span class="sr-only">Use setting</span>
                           <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                           <span
                              aria-hidden="true"
                              class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                           ></span>
                        </button>
                     </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                     <legend
                        class="text-base font-semibold leading-6 text-gray-900"
                     >
                        Columns
                     </legend>
                     @for (field of columns; track field.displayValue) {
                        <div class="flex flex-row items-center">
                           <input
                              type="checkbox"
                              [(ngModel)]="field.isSelected"
                              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                           />
                           <div class="flex flex-row items-baseline">
                              @switch (field.dataType) {
                                 @case ('string') {
                                    <p
                                       class="w-8 text-sm text-blue-600 font-mono"
                                    >
                                       Abc
                                    </p>
                                 }
                                 @case ('number') {
                                    <p
                                       class="w-8 text-sm text-blue-600 font-mono"
                                    >
                                       123
                                    </p>
                                 }
                                 @case ('date') {
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke-width="1.5"
                                       stroke="currentColor"
                                       class="h-5 w-8 font-mono text-blue-600"
                                    >
                                       <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                       />
                                    </svg>
                                 }
                                 @case ('measure') {
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke-width="1.25"
                                       stroke="currentColor"
                                       class="w-8 h-5 font-mono text-blue-600"
                                    >
                                       <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                                       />
                                    </svg>
                                 }
                                 @case ('link') {
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke-width="1.5"
                                       stroke="currentColor"
                                       class="w-8 h-5 font-mono text-blue-600"
                                    >
                                       <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                       />
                                    </svg>
                                 }
                              }
                              <p class="overflow-hidden text-gray-900">
                                 {{ field.displayValue }}
                              </p>
                           </div>
                        </div>
                     }
                     <legend
                        class="text-base font-semibold leading-6 text-gray-900 mt-4"
                     >
                        Filters
                     </legend>
                     <div>
                        <img
                           alt="query builder"
                           ngSrc="assets/query_builder.png"
                           height="656"
                           width="1094"
                        />
                     </div>
                     <legend
                        class="text-base font-semibold leading-6 text-gray-900 mt-4"
                     >
                        Sort
                     </legend>
                  </div>
               </div>
               <div class="flex flex-shrink-0 justify-end space-x-2 py-2 mr-2">
                  <button
                     type="button"
                     class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  >
                     Cancel
                  </button>
                  <button
                     type="button"
                     class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  >
                     Save Definition
                  </button>
                  <button
                     type="submit"
                     class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                     Apply
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
