import { Component, Input, signal, WritableSignal } from '@angular/core'
import { ModalComponent } from '../../shared/components/modal/modal.component'
import {
   SelectListComponent,
   SelectListOptions,
} from '../../shared/components/controls/select-list/select-list.component'

@Component({
   selector: 'app-section-user-security-form',
   standalone: true,
   imports: [ModalComponent, SelectListComponent],
   templateUrl: './section-user-security-form.component.html',
})
export class SectionUserSecurityFormComponent {
   @Input({ required: true }) $formIsDisplayed: WritableSignal<boolean> =
      signal(false)

   mockOptions: SelectListOptions[] = [
      { displayValue: 'Josh Hampl', returnValue: 1 },
      { displayValue: 'Gail Besner', returnValue: 2 },
   ]
}
