import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Conference, SearchResult } from '../shared/interfaces'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
export class ConferenceService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}

   // FYI: When you return to your laptop - this is what you were goi
   getConferenceScedule(conferenceID: number): Observable<Conference> {
      return this.http.get<Conference>(
         `${this.endpoint}/conference/${conferenceID}`
      )
   }

   createConference(
      sectionID: number,
      startTime: Date
   ): Observable<Conference> {
      return this.http.post<Conference>(
         `${this.endpoint}/conference`,
         JSON.stringify({
            sectionID: sectionID,
            startTime: startTime,
         })
      )
   }

   getConferencesForSection(
      sectionID: number,
      onlyNext?: boolean
   ): Observable<Conference[]> {
      let params = new HttpParams()
      params = params.append('sectionID', sectionID)
      if (onlyNext) {
         params = params.append('relation', 'next')
      }
      return this.http.get<Conference[]>(`${this.endpoint}/conference`, {
         params: params,
      })
   }
}
