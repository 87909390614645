import {
   Component,
   ElementRef,
   HostListener,
   inject,
   ViewChild,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import {
   FloatingOptionsMenuComponent,
   optionsMenu,
} from '../floating-options-menu/floating-options-menu.component'
import { UserService } from '../../services/user.service'
import { Router, RouterModule } from '@angular/router'

@Component({
   selector: 'app-nav-bar',
   standalone: true,
   imports: [CommonModule, FloatingOptionsMenuComponent, RouterModule],
   templateUrl: './nav-bar.component.html',
})
export class NavBarComponent {
   constructor(
      public userService: UserService,
      private router: Router
   ) {}

   // Focus on Search when "/" is pressed, navigate to Search Results onEnter and push the search term to the "q" queryParam
   @ViewChild('searchInput') searchInput:
      | ElementRef<HTMLInputElement>
      | undefined
   @HostListener('window:keyup./')
   handleSlashKey() {
      this.searchInput?.nativeElement.focus()
   }
   @HostListener('window:keyup.enter', ['$event'])
   search(event: KeyboardEvent) {
      if (event.target === this.searchInput?.nativeElement) {
         this.router.navigate(['search'], {
            queryParams: { q: this.searchInput?.nativeElement.value },
         })
         this.searchInput!.nativeElement.value = ''
      }
   }

   profilePath = this.userService.getAuthenticatedUser().profilePicPath

   userMenuIsDisplayed: boolean = false
   userMenuOptions: optionsMenu = [
      [
         {
            key: 'PROFILE_NAME',
            displayValue: this.userService.getAuthenticatedUser()
               ? this.userService.getAuthenticatedUser().name
               : 'Anonymous',
            isSelected: false,
         },
         { key: 'MY_PROFILE', displayValue: 'My Profile', isSelected: false },
         { key: 'LOGOUT', displayValue: 'Logout', isSelected: false },
      ],
   ]
   protected readonly inject = inject
}
