import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
   selector: 'app-button-danger',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './danger.component.html',
})
export class DangerComponent {
   @Input() label: string = ''
}
