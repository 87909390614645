import { Component } from '@angular/core'
import { InteractiveTableColumn } from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'

@Component({
   selector: 'app-admin-tab-subscription',
   standalone: true,
   imports: [InteractiveTableComponent, ActionTabComponent, ButtonComponent],
   templateUrl: './admin-tab-subscription.component.html',
})
export class AdminTabSubscriptionComponent {
   // FIXME: This is a mock
   columns: InteractiveTableColumn[] = [
      {
         key: 'licensedTo',
         displayValue: 'Licensed To',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'start',
         displayValue: 'Start',
         dataType: 'date',
         sortIndex: 1,
         isVisible: true,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'end',
         displayValue: 'End',
         dataType: 'date',
         sortIndex: 2,
         isVisible: true,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'fingerprint',
         displayValue: 'Fingerprint',
         dataType: 'string',
         sortIndex: 3,
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'status',
         displayValue: 'Status',
         dataType: 'string',
         sortIndex: 3,
         isVisible: true,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
   ]
   data = [
      {
         licensedTo: "Nationwide Children's Hospital",
         start: 'Jan 01, 2024',
         end: 'Dec 31, 2025',
         fingerprint: 'SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
         status: 'Active',
      },
   ]
}
