import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { MonarchCaseTab, Outcome } from '../../../shared/interfaces'
import {
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { OutcomeFormComponent } from './outcome-form.component'

@Component({
   selector: 'app-tab-case-outcomes',
   standalone: true,
   imports: [CommonModule, OutcomeFormComponent, SimpleListComponent],
   templateUrl: './tab-case-outcomes.component.html',
})
export class TabCaseOutcomes implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) allowEditing: boolean = false
   $caseOutcomes: WritableSignal<Outcome[]> = signal([])
   $outcomeFormIsDisplayed: WritableSignal<boolean> = signal(false)
   caseOutcomesDisplayList: SimpleList = []
   selectedCaseOutcomeID: number = 0

   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.caseOutcomesDisplayList = this.$caseOutcomes().map((x: Outcome) =>
            mapOutcomeToListItem(x)
         )
      })
   }

   ngOnInit() {
      this.caseManagementService.getRelatedOutcomes(this.caseID).subscribe({
         next: (value) =>
            this.$caseOutcomes.set(value.filter((x) => !x.isDeleted)),
      })
   }
}

function mapOutcomeToListItem(outcome: Outcome): SimpleListItem {
   let createDate = new Date(outcome.createdAt)

   return {
      id: outcome.id,
      title: outcome.outcomeName!,
      titleTooltip: outcome.outcomeDescription,
      description: outcome.note,
      propertyPairs: [
         {
            key: 'CREATEDATE',
            display: 'Created',
            value: createDate.toLocaleString(),
         },
         {
            key: 'CREATEDBY',
            display: 'By',
            value: outcome.createdBy,
         },
      ],
   }
}
