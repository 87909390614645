import {
   Component,
   HostListener,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CaseManagementService } from '../../../services/case-management.service'
import { Outcome } from '../../../shared/interfaces'
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { NgFor, NgIf } from '@angular/common'
import { isNewRecord } from '../tab-failures-modes/failure-mode-form.component'
import {
   CREATED,
   NotificationService,
   NotificationType,
   UPDATED,
} from '../../../services/notification.service'
import { DangerComponent } from '../../../shared/components/button/danger/danger.component'
import { AdminService } from '../../../services/admin.service'

@Component({
   selector: 'app-outcome-form',
   templateUrl: './outcome-form.component.html',
   standalone: true,
   imports: [NgIf, ReactiveFormsModule, NgFor, DangerComponent],
})
export class OutcomeFormComponent implements OnInit {
   // MARK: Constructor
   constructor(
      private caseManagementService: CaseManagementService,
      private NotificationService: NotificationService,
      private adminService: AdminService
   ) {}

   // MARK: Listeners
   @HostListener('window:keydown.esc', ['$event'])
   handleEscapeKey() {
      this.cancel()
   }

   @HostListener('window:keydown.enter', ['$event'])
   handleEnterKey() {
      this.submit()
   }

   // MARK: Properties
   @Input() caseID: number = 0
   @Input() selectedCaseOutcomeID: number = 0
   @Input() $caseOutcomes: WritableSignal<Outcome[]> | undefined
   @Input() $formIsDisplayed: WritableSignal<boolean> = signal(false)
   dataIsLoaded: boolean = false

   outcomeForm = new FormGroup({
      outcomeID: new FormControl<number>(null!, {
         validators: [Validators.required],
      }),
      note: new FormControl(),
   })

   outcomeOptions: { id: number; name: string }[] = []

   // MARK: Lifecycle
   ngOnInit() {
      this.adminService.getCapabilities('Outcome').subscribe({
         next: (value) => {
            this.outcomeOptions = value
            console.log(this.outcomeOptions)
         },
      })

      if (!isNewRecord(this.selectedCaseOutcomeID)) {
         this.caseManagementService
            .getOutcome(this.caseID, this.selectedCaseOutcomeID)
            .subscribe({
               next: (value) => {
                  this.outcomeForm.patchValue({
                     outcomeID: value.outcomeID,
                     note: value.note,
                  })
                  this.outcomeForm.controls['outcomeID'].disable()
               },
               complete: () => (this.dataIsLoaded = true),
            })
      } else {
         this.dataIsLoaded = true
      }
   }

   // MARK: Methods
   submit() {
      if (isNewRecord(this.selectedCaseOutcomeID)) {
         this.createOutcome()
      } else {
         this.updateOutcome()
      }
   }

   cancel() {
      this.$formIsDisplayed.set(false)
   }

   delete() {
      this.caseManagementService
         .deleteOutcome(this.caseID, this.selectedCaseOutcomeID)
         .subscribe({
            next: () => {
               const index = this.$caseOutcomes!().findIndex(
                  (outcome) => outcome.id === this.selectedCaseOutcomeID
               )
               if (index > -1) {
                  this.$caseOutcomes!.update(() =>
                     this.$caseOutcomes!().toSpliced(index, 1)
                  )
               }
            },
            complete: () => {
               this.$formIsDisplayed.set(false)
               this.NotificationService.new(
                  NotificationType.Info,
                  'Successfully deleted'
               )
            },
         })
   }

   createOutcome() {
      this.caseManagementService
         .createOutcome({
            caseID: this.caseID,
            outcomeID: this.outcomeForm.controls['outcomeID'].getRawValue()!,
            note: this.outcomeForm.controls['note'].getRawValue(),
         })
         .subscribe({
            next: (value) =>
               this.$caseOutcomes?.update((list) => [...list, value]),
            complete: () => {
               this.$formIsDisplayed?.set(false)
               this.NotificationService.new(
                  NotificationType.OK,
                  CREATED,
                  'Outcome was successfully added to Case'
               )
            },
         })
   }

   updateOutcome() {
      this.caseManagementService
         .updateOutcome({
            caseID: this.caseID,
            caseOutcomeID: this.selectedCaseOutcomeID,
            note: this.outcomeForm.controls['note'].getRawValue(),
         })
         .subscribe({
            next: (updatedCaseOutcome) => {
               this.$caseOutcomes?.update((items) =>
                  items.map((item) =>
                     item.id === this.selectedCaseOutcomeID
                        ? updatedCaseOutcome
                        : item
                  )
               )
            },
            complete: () => {
               this.NotificationService.new(NotificationType.OK, UPDATED)
               this.$formIsDisplayed.set(false)
            },
         })
   }

   protected readonly isNewRecord = isNewRecord
}
