<div id="actionItems">
   <div class="flex justify-between border-b border-gray-100 py-2">
      <div class="flex items-center">
         <p
            class="text-base font-semibold leading-6 text-gray-900 align-middle"
         >
            Action Items
         </p>
      </div>
      <div>
         @if (allowEditing) {
            <button
               type="button"
               (click)="
                  $actionItemFormIsDisplayed.set(true); selectedActionItemID = 0
               "
               class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
               New Action Item
            </button>
         }
      </div>
   </div>

   <!--Content-->
   <div class="flex">
      <app-simple-list
         [listData]="actionItemsDisplayList"
         (selectionEvent)="
            selectedActionItemID = $event.id;
            $actionItemFormIsDisplayed.set(true)
         "
         class="w-full mr-2"
      ></app-simple-list>

      <!--Right Side-->

      @if ($actionItemFormIsDisplayed()) {
         <app-action-item-form
            [caseID]="caseID"
            [$caseActionItems]="$caseActionItems"
            [$formIsDisplayed]="$actionItemFormIsDisplayed"
            [selectedActionItemID]="selectedActionItemID"
            class="w-2/3"
         ></app-action-item-form>
      }
   </div>
</div>
