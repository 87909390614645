import {
   Component,
   EventEmitter,
   HostListener,
   Input,
   Output,
} from '@angular/core'
import { CaseManagementService } from '../../../services/case-management.service'
import { Document } from '../../../shared/interfaces'
import { NgClass } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

@Component({
   selector: 'app-file-upload',
   templateUrl: './file-upload-form.component.html',
   standalone: true,
   imports: [ReactiveFormsModule, FormsModule, NgClass],
})
export class FileUploadFormComponent {
   constructor(private caseManagementService: CaseManagementService) {}

   @Input() caseID: number = 0
   @Output() uploadedFileEmitter: EventEmitter<Document> = new EventEmitter()
   dropzoneIsFocused = false

   @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
      // The sole purpose of this is to cancel the default dragover event to allow for a subsequent drop event
      event.preventDefault()
      event.stopPropagation()
      this.dropzoneIsFocused = true
   }

   @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
      event.preventDefault()
      event.stopPropagation()
      this.dropzoneIsFocused = false
   }

   // The dropzone facilitates upload of multiple files at once. Files uploaded via dropzone are manually appended
   // to a form. Angular will supply the boundary automatically.
   @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
      event.preventDefault()
      event.stopPropagation()
      this.dropzoneIsFocused = false
      let files = event.dataTransfer!.files
      let form = new FormData()
      for (let i = 0; i < files.length; i++) {
         form.append('file', files[i], files[i].name)
      }
      this.caseManagementService.uploadDocument(this.caseID, form).subscribe({
         next: (value) => console.log(value),
      })
   }

   // uploadFile is used when files are manually selected via the file input control
   uploadFile() {
      let form = document.forms.namedItem('fileUploadDropzone')
      let input = new FormData(form!)
      this.caseManagementService.uploadDocument(this.caseID, input).subscribe({
         next: (uploadedFiles) => {
            uploadedFiles.forEach((file) => {
               this.uploadedFileEmitter.emit(file)
               console.log(file)
            })
         },
      })
   }
}
