import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
   Practitioner,
   SearchResultEncounter,
   SearchResultPatient,
   SearchResultProcedure,
} from '../shared/interfaces'
import { HttpClient } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
export class FhirService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}

   listPatients(mrn: string): Observable<SearchResultPatient[]> {
      return this.http.get<SearchResultPatient[]>(
         `${this.endpoint}/fhir/patient?identifier=${mrn}`
      )
   }

   listEncounters(patientFhirID: string): Observable<SearchResultEncounter[]> {
      return this.http.get<SearchResultEncounter[]>(
         `${this.endpoint}/fhir/encounter?patient=${patientFhirID}`
      )
   }

   listProcedures(patientFhirID: string): Observable<SearchResultProcedure[]> {
      return this.http.get<SearchResultProcedure[]>(
         `${this.endpoint}/fhir/procedure?patient=${patientFhirID}`
      )
   }

   listPractitioners(searchString: string): Observable<Practitioner[]> {
      return this.http.get<Practitioner[]>(
         `${this.endpoint}/fhir/practitioner?name=${searchString}`
      )
   }
}
