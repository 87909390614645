import {
   ChangeDetectorRef,
   Component,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CaseManagementService } from '../../services/case-management.service'
import { MonarchCase, ClinicalSection } from '../../shared/interfaces'
import { ProgressBarService } from '../../services/progress-bar.service'
import { CaseListComponent } from '../../views/case-list/case-list.component'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserService } from '../../services/user.service'
import { ConferenceService } from '../../services/conference.service'
import { TabSelectorComponent } from '../../components/tab-selector/tab-selector.component'
import { HomeTabNextConferenceComponent } from './home-tab-next-conference/home-tab-next-conference.component'
import { HomeTabRecentlyViewedComponent } from './home-tab-recently-viewed/home-tab-recently-viewed.component'
import { HomeTabOpenCasesComponent } from './home-tab-open-cases/home-tab-open-cases.component'

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   standalone: true,
   imports: [
      ReactiveFormsModule,
      ButtonComponent,
      CaseListComponent,
      FormsModule,
      TabSelectorComponent,
      HomeTabNextConferenceComponent,
      HomeTabRecentlyViewedComponent,
      HomeTabOpenCasesComponent,
   ],
})
export class HomeComponent implements OnInit {
   constructor(
      private userService: UserService,
      private ref: ChangeDetectorRef
   ) {}

   accessibleSections: ClinicalSection[] = []
   $selectedSection: WritableSignal<number> = signal(0)
   $activeTab: WritableSignal<string> = signal('')

   ngOnInit() {
      console.log('HomeComponent initialized')
      this.$activeTab.set('Open Cases')
      this.getSectionsAccessibleToUser()
   }

   getSectionsAccessibleToUser() {
      this.accessibleSections = this.userService.getAccessibleSections()
      if (this.accessibleSections.length > 0) {
         this.$selectedSection.set(this.accessibleSections[0].sectionID) // Default to the first
      }
   }
}
