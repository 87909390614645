import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { Capability, InteractiveTableColumn } from '../../../shared/interfaces'
import { AdminService } from '../../../services/admin.service'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'

@Component({
   selector: 'app-admin-tab-dropdowns',
   standalone: true,
   imports: [InteractiveTableComponent, ActionTabComponent],
   templateUrl: './admin-tab-dropdowns.component.html',
})
export class AdminTabDropdownsComponent implements OnInit {
   adminService = inject(AdminService)
   standardizedColumns: InteractiveTableColumn[] = [
      {
         key: 'id',
         displayValue: 'ID',
         isVisible: true,
         sortIndex: 0,
         dataType: 'number',
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'name',
         displayValue: 'Title',
         isVisible: true,
         sortIndex: 1,
         dataType: 'string',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'description',
         displayValue: 'Description',
         isVisible: true,
         sortIndex: 2,
         dataType: 'number',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      // {
      //    key: 'abbreviation',
      //    displayValue: 'Abbreviation',
      //    isVisible: true,
      //    sortIndex: 3,
      //    dataType: 'string',
      //    headerAlignment: 'left',
      //    cellAlignment: 'left',
      // },
   ]
   levelOfPreventabilityOptions: Capability[] = []
   $levelOfPreventabilityFormVisible: WritableSignal<boolean> = signal(false)
   $levelOfPreventabilityIsProcessing: WritableSignal<boolean> = signal(false)

   changeInClinicalStatusOptions: Capability[] = []
   $changeInClinicalStatusFormVisible: WritableSignal<boolean> = signal(false)
   $changeInClinicalStatusIsProcessing: WritableSignal<boolean> = signal(false)

   categorizationOptions: Capability[] = []
   $categorizationFormVisible: WritableSignal<boolean> = signal(false)
   $categorizationIsProcessing: WritableSignal<boolean> = signal(false)

   // TODO: Should fetch in parallel
   ngOnInit() {
      this.getLevelOfPreventabilityOptions()
      this.getChangeInClinicalStatusOptions()
      this.getCategorizationOptions()
   }

   getLevelOfPreventabilityOptions() {
      this.$levelOfPreventabilityIsProcessing.set(true)
      this.adminService.getCapabilities('PreventabilityLevel').subscribe({
         next: (value) => (this.levelOfPreventabilityOptions = value.slice(0)),
         complete: () => this.$levelOfPreventabilityIsProcessing.set(false),
      })
   }

   getChangeInClinicalStatusOptions() {
      this.$changeInClinicalStatusIsProcessing.set(true)
      this.adminService.getCapabilities('ChangeInClinicalStatus').subscribe({
         next: (value) => (this.changeInClinicalStatusOptions = value.slice(0)),
         complete: () => this.$changeInClinicalStatusIsProcessing.set(false),
      })
   }

   getCategorizationOptions() {
      this.$categorizationIsProcessing.set(true)
      this.adminService.getCapabilities('CaseCategory').subscribe({
         next: (value) => (this.categorizationOptions = value.slice(0)),
         complete: () => this.$categorizationIsProcessing.set(false),
      })
   }
}
