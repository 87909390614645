<form [formGroup]="conferenceForm">
   <div class="flex flex-col">
      <p class="text-2xl font-bold leading-7 text-gray-900 mr-4 py-1.5">
         New Conference
      </p>

      <div class="flex flex-row space-x-2">
         <div class="mt-2 w-64">
            <label
               for="sectionID"
               class="block text-sm font-medium leading-6 text-gray-900"
               >Section</label
            >
            <select
               id="sectionID"
               name="sectionID"
               formControlName="sectionID"
               class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
               <option [ngValue]="null">None</option>
               @for (section of selectableSections; track $index) {
                  <option [ngValue]="section.sectionID">
                     {{ section.name }}
                  </option>
               }
            </select>
         </div>
         <app-input-date
            formControlName="startTime"
            [label]="'Date'"
            [name]="'conferenceDate'"
         ></app-input-date>
         <div class="flex flex-col justify-end">
            <app-button
               label="Create"
               buttonType="primary"
               (click)="create()"
            ></app-button>
         </div>
      </div>

      <!--Disabled for now -- room reserved for later-->
      <!--<div-->
      <!--   class="fixed bottom-0 right-0 w-full py-4 pr-4 backdrop-blur bg-white bg-opacity-90 border-t border-t-gray-300"-->
      <!--&gt;-->
      <!--   <div class="flex flex-row float-right">-->
      <!--      <app-button label="Create" buttonType="primary"></app-button>-->
      <!--   </div>-->
      <!--</div>-->
      <!--<div>-->
      <!--   <p>Add / Remove Cases</p>-->
      <!--</div>-->
   </div>
</form>
