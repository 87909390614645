import { bootstrapApplication } from '@angular/platform-browser'
import { AppComponent } from './app/app.component'
import { provideRouter, Router, Routes } from '@angular/router'
import { HomeComponent } from './app/pages/home/home.component'
import { LoginComponent } from './app/pages/login/login.component'
import { CaseReportComponent } from './app/pages/case-list/case-report.component'
import { CaseEntryFormComponent } from './app/forms/case-entry/case-entry-form.component'
import { CaseEditorPageComponent } from './app/pages/case-editor/case-editor-page.component'
import { SearchComponent } from './app/pages/search/search.component'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { ConferencesComponent } from './app/pages/conferences/conferences/conferences.component'
import { AdministrationPageComponent } from './app/pages/administration/administration-page.component'
import { UserService } from './app/services/user.service'
import { inject } from '@angular/core'
import { authInterceptor } from './app/interceptors/auth.interceptor'
import { ActionItemReportComponent } from './app/pages/action-item-report/action-item-report.component'
import { AdverseEventReportComponent } from './app/pages/adverse-event-report/adverse-event-report.component'
import { OutcomeReportComponent } from './app/pages/outcome-report/outcome-report.component'
import { FailureModeReportComponent } from './app/pages/failure-mode-report/failure-mode-report.component'
import { ConferenceFormComponent } from './app/forms/conference/conference-form.component'
import { httpErrorInterceptor } from './app/interceptors/http-error.interceptor'
import { UserActivationComponent } from './app/pages/user-activation/user-activation.component'
import { authGuard } from './app/guards/auth.guard'
import { NotFoundComponent } from './app/pages/not-found/not-found.component'

const routes: Routes = [
   { path: 'login', component: LoginComponent, title: 'Monarch Login' },
   {
      path: 'activateAccount',
      component: UserActivationComponent,
      title: 'Activate Your Account',
   },
   {
      path: 'home',
      component: HomeComponent,
      title: 'Monarch Home',
      canActivate: [authGuard],
   },
   {
      path: 'cases',
      component: CaseReportComponent,
      title: 'Case List',
      canActivate: [authGuard],
   },
   {
      path: 'case',
      canActivateChild: [authGuard],
      children: [
         { path: 'new', component: CaseEntryFormComponent, title: 'New Case' },
         {
            path: `:id`,
            component: CaseEditorPageComponent,
            title: 'Case Editor',
         },
      ],
   },
   {
      path: 'search',
      component: SearchComponent,
      canActivate: [authGuard],
      title: 'Search Results',
   },
   {
      path: 'conferences',
      component: ConferencesComponent,
      canActivate: [authGuard],
      title: 'Conferences',
   },
   {
      path: 'actionItems',
      component: ActionItemReportComponent,
      canActivate: [authGuard],
      title: 'Action Items',
   },
   {
      path: 'adverseEvents',
      component: AdverseEventReportComponent,
      canActivate: [authGuard],
      title: 'Adverse Events',
   },
   {
      path: 'outcomes',
      component: OutcomeReportComponent,
      canActivate: [authGuard],
      title: 'Outcomes',
   },
   {
      path: 'failureModes',
      component: FailureModeReportComponent,
      canActivate: [authGuard],
      title: 'Failure Modes',
   },
   {
      path: 'administration',
      component: AdministrationPageComponent,
      canActivate: [authGuard],
      title: 'Administration',
   },
   {
      path: 'conference',
      canActivateChild: [authGuard],
      children: [
         {
            path: 'new',
            component: ConferenceFormComponent,
            title: 'New Conference',
         },
      ],
   },
   { path: '', redirectTo: 'home', pathMatch: 'full' }, // Must pathMatch full when checking an empty route

   {
      path: '**',
      component: NotFoundComponent,
      title: '404 Not Found',
   },
]

bootstrapApplication(AppComponent, {
   providers: [
      provideRouter(routes),
      provideHttpClient(
         withInterceptors([httpErrorInterceptor, authInterceptor])
      ),
      provideAnimations(),
   ],
}).catch((error) => console.log(error))
