<app-modal title="New User" [$isDisplayed]="$formIsDisplayed">
   <form [formGroup]="userForm">
      <div class="flex flex-row">
         <app-input-number
            label="User ID"
            name="userID"
            formControlName="userID"
         ></app-input-number>
      </div>

      <div class="flex flex-row space-x-1">
         <app-input-text
            label="First Name"
            name="firstName"
            formControlName="firstName"
         ></app-input-text>
         <app-input-text
            label="Last Name"
            name="lastName"
            formControlName="lastName"
         ></app-input-text>
      </div>

      <div class="flex flex-row space-x-1">
         <app-input-text
            label="Email"
            name="email"
            formControlName="email"
         ></app-input-text>
      </div>

      <div class="w-1/3">
         <div class="flex items-center justify-between mt-2">
            <span class="flex flex-grow flex-col">
               <span
                  class="text-sm font-medium leading-6 text-gray-900"
                  id="availability-label"
                  >Instance Administrator?</span
               >
               <span class="text-sm text-gray-500" id="availability-description"
                  >Has full control over Monarch platform</span
               >
            </span>
            <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
            <button
               type="button"
               class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
               role="switch"
               aria-checked="false"
               aria-labelledby="availability-label"
               aria-describedby="availability-description"
            >
               <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
               <span
                  aria-hidden="true"
                  class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
               ></span>
            </button>
         </div>
      </div>

      <!--Footer-->
      <!--<div class="mt-2">-->
      <!--   <div class="flex justify-between">-->
      <!--      <div class="flex flex-row space-x-1">-->
      <!--         <button-->
      <!--            type="button"-->
      <!--            class="rounded-md mt-2 bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:ring-1 ring-inset ring-gray-300 hover:bg-gray-50"-->
      <!--         >-->
      <!--            &lt;!&ndash;(click)="$formIsDisplayed.set(false)"&ndash;&gt;-->
      <!--            Cancel-->
      <!--         </button>-->
      <!--         &lt;!&ndash;@if (!isNewRecord(this.selectedCaseOutcomeID)) {&ndash;&gt;-->
      <!--         &lt;!&ndash;   <app-button-danger&ndash;&gt;-->
      <!--         &lt;!&ndash;      [label]="'Delete'"&ndash;&gt;-->
      <!--         &lt;!&ndash;      (click)="delete()"&ndash;&gt;-->
      <!--         &lt;!&ndash;   ></app-button-danger>&ndash;&gt;-->
      <!--         &lt;!&ndash;}&ndash;&gt;-->
      <!--      </div>-->
      <!--      &lt;!&ndash;(click)="submit()"&ndash;&gt;-->
      <!--      &lt;!&ndash;<button&ndash;&gt;-->
      <!--      &lt;!&ndash;   type="button"&ndash;&gt;-->
      <!--      &lt;!&ndash;   class="float-right mt-2 inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"&ndash;&gt;-->
      <!--      &lt;!&ndash;&gt;&ndash;&gt;-->
      <!--      &lt;!&ndash;   Save&ndash;&gt;-->
      <!--      &lt;!&ndash;</button>&ndash;&gt;-->
      <!--   </div>-->
      <!--</div>-->
   </form>
   <div class="ngc-footer-right">
      <app-button label="Submit" (click)="submit()"></app-button>
   </div>
</app-modal>
