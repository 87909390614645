import { Component, effect, Input, OnInit, WritableSignal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkTreeModule, FlatTreeControl } from '@angular/cdk/tree'
import { ArrayDataSource } from '@angular/cdk/collections'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { CaseManagementService } from '../../../services/case-management.service'
import { LoggingService } from '../../../services/logging.service'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { AdminService } from '../../../services/admin.service'
import {
   CaseFailure,
   FailureModeTaxonomy,
   FailureModeTaxonomyNode,
} from '../../../shared/interfaces'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'
import { DangerComponent } from '../../../shared/components/button/danger/danger.component'

@Component({
   selector: 'app-failure-mode',
   standalone: true,
   imports: [
      CommonModule,
      CdkTreeModule,
      MatButtonModule,
      MatIconModule,
      ReactiveFormsModule,
      DangerComponent,
   ],
   templateUrl: './failure-mode-form.component.html',
   styles: `
      .example-tree-node {
         display: flex;
         align-items: center;
      }
   `,
})
export class FailureModeFormComponent implements OnInit {
   constructor(
      public API: CaseManagementService,
      public logger: LoggingService,
      private adminService: AdminService,
      private notificationService: NotificationService
   ) {
      effect(() => {
         this.debugFormMode()
         this.logger.debug(
            `Editing Case Failure ID: ${this.selectedCaseFailureID}`
         )
      })
   }

   ngOnInit() {
      this.adminService.getFailureModeTaxonomy().subscribe({
         next: (value) => {
            this.failureModeTaxonomy = value.slice()
            this.dataSource = new ArrayDataSource(this.failureModeTaxonomy)
         },
      })
      if (!isNewRecord(this.selectedCaseFailureID)) {
         this.getRecord()
         this.failureModeForm.controls['failureModeID'].disable()
      }
   }

   // MARK: Properties
   @Input() selectedCaseFailureID: number = 0 // The ID of the Case Failure, if we're editing
   @Input() $caseFailures: WritableSignal<CaseFailure[]> | undefined // The complete list of failure modes associated with this case, which will be mutated
   @Input() $formIsDisplayed: WritableSignal<boolean> | undefined
   @Input() caseID: number = 0

   dataIsLoaded: boolean = true
   selectedFailureModeID: number | undefined
   failureModeTaxonomy: FailureModeTaxonomy = []
   dataSource: ArrayDataSource<FailureModeTaxonomyNode> | undefined

   failureModeForm = new FormGroup({
      failureModeID: new FormControl(),
      note: new FormControl('', { nonNullable: true }),
   })

   setSelectedNode(f: FailureModeTaxonomyNode) {
      this.selectedFailureModeID = f.failureModeID
      this.failureModeForm.controls['failureModeID'].patchValue(f.failureModeID)
      console.log(f)
   }

   debugFormMode() {
      if (isNewRecord(this.selectedCaseFailureID)) {
         this.logger.debug('Form opened in CREATE mode')
      } else {
         this.logger.debug('Form opened in EDIT mode')
      }
   }

   getRecord() {
      this.API.getFailure(this.caseID, this.selectedCaseFailureID).subscribe({
         next: (value) => {
            this.failureModeForm.patchValue({
               failureModeID: value.failureModeID,
               note: value.note,
            })
         },
      })
   }

   submit() {
      if (isNewRecord(this.selectedCaseFailureID)) {
         this.API.createFailure(this.caseID, {
            failureModeID:
               this.failureModeForm.controls['failureModeID'].getRawValue(),
            note: this.failureModeForm.controls['note'].getRawValue(),
         }).subscribe({
            next: (failureMode: CaseFailure) => {
               this.$caseFailures?.update((list) => [...list, failureMode])
            },
            complete: () => {
               this.selectedCaseFailureID = 0
               this.$formIsDisplayed?.set(false)
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully created'
               )
            },
         })
      } else {
         this.API.updateFailure(
            this.caseID,
            this.selectedCaseFailureID,
            this.failureModeForm.controls['note'].getRawValue()
         ).subscribe({
            next: (failureMode: CaseFailure) => {
               this.$caseFailures?.update((items) =>
                  items.map((item) =>
                     item.id === this.selectedCaseFailureID ? failureMode : item
                  )
               )
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully updated'
               )
            },
            complete: () => {
               this.selectedCaseFailureID = 0
               this.$formIsDisplayed?.set(false)
            },
         })
      }
   }

   delete() {
      this.API.deleteFailure(this.caseID, this.selectedCaseFailureID).subscribe(
         {
            next: () => {
               const index = this.$caseFailures!().findIndex(
                  (caseFailure) => caseFailure.id === this.selectedCaseFailureID
               )
               if (index > -1) {
                  this.$caseFailures?.update(() =>
                     this.$caseFailures!().toSpliced(index, 1)
                  )
               }
            },
            complete: () => {
               this.selectedCaseFailureID = 0
               this.$formIsDisplayed?.set(false)
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully deleted'
               )
            },
         }
      )
   }

   treeControl = new FlatTreeControl<FailureModeTaxonomyNode>(
      (node) => node.depth,
      (node) => node.isExpandable
   )

   getParentNode(node: FailureModeTaxonomyNode) {
      const nodeIndex = this.failureModeTaxonomy.indexOf(node)

      for (let i = nodeIndex - 1; i >= 0; i--) {
         if (this.failureModeTaxonomy[i].depth === node.depth - 1) {
            return this.failureModeTaxonomy[i]
         }
      }

      return null
   }

   hasChild = (_: number, node: FailureModeTaxonomyNode) => node.isExpandable

   shouldRender(node: FailureModeTaxonomyNode) {
      let parent = this.getParentNode(node)
      while (parent) {
         if (!parent.isExpanded) {
            return false
         }
         parent = this.getParentNode(parent)
      }
      return true
   }

   protected readonly isNewRecord = isNewRecord
}

export function isNewRecord(id: number): boolean {
   return id === 0
}
