import { InteractiveTableColumn } from './interfaces'

export let encounterSearchResultColumns: InteractiveTableColumn[] = [
   {
      key: 'EncounterCSN',
      sortIndex: 0,
      displayValue: 'CSN',
      dataType: 'number',
      isVisible: true,
   },
   {
      key: 'Type',
      sortIndex: 1,
      displayValue: 'Encounter Type',
      dataType: 'string',
      isVisible: true,
   },
   {
      key: 'Start',
      sortIndex: 2,
      displayValue: 'Start',
      dataType: 'datetime',
      isVisible: true,
   },
   {
      key: 'End',
      sortIndex: 3,
      displayValue: 'End / Discharge',
      dataType: 'datetime',
      isVisible: true,
   },
]

export let patientSearchResultColumns: InteractiveTableColumn[] = [
   {
      key: 'MRN',
      sortIndex: 0,
      displayValue: 'MRN',
      dataType: 'number',
      isVisible: true,
   },
   {
      key: 'LastName',
      sortIndex: 1,
      displayValue: 'Last Name',
      dataType: 'string',
      isVisible: true,
   },
   {
      key: 'FirstName',
      sortIndex: 2,
      displayValue: 'First Name',
      dataType: 'string',
      isVisible: true,
   },
   {
      key: 'DOB',
      sortIndex: 3,
      displayValue: 'DOB',
      dataType: 'date',
      isVisible: true,
   },
   {
      key: 'Gender',
      sortIndex: 4,
      displayValue: 'Gender',
      dataType: 'string',
      isVisible: true,
   },
]

export let procedureSearchResultColumns: InteractiveTableColumn[] = [
   {
      key: 'performedAt',
      sortIndex: 0,
      displayValue: 'Performed On',
      dataType: 'date',
      isVisible: true,
   },
   {
      key: 'name',
      sortIndex: 1,
      displayValue: 'Name',
      dataType: 'string',
      isVisible: true,
   },
   {
      key: 'performedBy',
      sortIndex: 2,
      displayValue: 'By',
      dataType: 'string',
      isVisible: true,
   },
   {
      key: 'status',
      sortIndex: 3,
      displayValue: 'Status',
      dataType: 'string',
      isVisible: true,
   },
]
