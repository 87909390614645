import { Component, OnInit } from '@angular/core'
import { CaseManagementService } from '../../services/case-management.service'
import {
   ClinicalSection,
   CreateCaseInputs,
   InteractiveTableColumn,
   SearchResultEncounter,
   SearchResultPatient,
   SearchResultProcedure,
} from '../../shared/interfaces'
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { DatePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common'
import { Router } from '@angular/router'
import { UserService } from '../../services/user.service'
import { ZeroDatePipePipe } from '../../pipes/zero-date.pipe'
import { FhirService } from '../../services/fhir.service'
import { InteractiveTableComponent } from '../../shared/components/table/interactive-table.component'
import { InputTextComponent } from '../../shared/components/controls/input-text/input-text.component'
import { InputNumberComponent } from '../../shared/components/controls/input-number/input-number.component'
import { BannerComponent } from '../../shared/components/banners/banner/banner.component'
import {
   NotificationService,
   NotificationType,
} from '../../services/notification.service'
import {
   encounterSearchResultColumns,
   patientSearchResultColumns,
   procedureSearchResultColumns,
} from '../../shared/tableColumnConfiguration'

@Component({
   selector: 'app-case-entry-form',
   templateUrl: './case-entry-form.component.html',
   standalone: true,
   imports: [
      ReactiveFormsModule,
      NgIf,
      NgFor,
      ButtonComponent,
      TitleCasePipe,
      DatePipe,
      ZeroDatePipePipe,
      InteractiveTableComponent,
      InputTextComponent,
      InputNumberComponent,
      BannerComponent,
   ],
})
export class CaseEntryFormComponent implements OnInit {
   constructor(
      private caseManagementService: CaseManagementService,
      private router: Router,
      private userService: UserService,
      private fhirService: FhirService,
      private notificationService: NotificationService
   ) {}

   userAccessibleSections: ClinicalSection[] | undefined
   patientSearchResults: SearchResultPatient[] = []
   encounterSearchResults: SearchResultEncounter[] = []
   procedureSearchResults: SearchResultProcedure[] = []
   patientsNotFound = false
   encountersNotFound = false
   proceduresNotFound = false
   patientSearchResultColumns = patientSearchResultColumns
   encounterSearchResultColumns = encounterSearchResultColumns
   procedureSearchResultColumns = procedureSearchResultColumns
   ngOnInit() {
      // Fetch the list of Sections this user has permission to Create Cases in, and default the select list to the first one
      this.userAccessibleSections = this.userService.getAccessibleSections()
      this.pageForm.controls.currentSelections.controls.sectionID.setValue(
         this.userAccessibleSections[0].sectionID
      )

      // Monitor for changes to the selected Encounter or Procedure; when changed, re-fetch downstreams
      let currentSelections = this.pageForm.controls.currentSelections.controls
      currentSelections.patientFhirID.valueChanges.subscribe({
         next: (value) => this.searchForEncounter(),
      })
      currentSelections.encounterFhirID.valueChanges.subscribe({
         next: (value) => this.searchForProcedure(),
      })
   }

   pageForm = new FormGroup({
      currentSelections: new FormGroup({
         sectionID: new FormControl(0, Validators.required),
         patientFhirID: new FormControl(''),
         encounterFhirID: new FormControl(''),
         procedureFhirID: new FormControl(''),
      }),
      patientSearchCriteria: new FormGroup({
         MRN: new FormControl(),
         birthDate: new FormControl(),
         givenName: new FormControl(),
         familyName: new FormControl(),
      }),
      encounterSearchCriteria: new FormGroup({
         CSN: new FormControl(),
         Type: new FormControl(),
         EndDate: new FormControl(),
      }),
   })

   // TODO: P2 - Only searching by MRN right now
   searchForPatient() {
      // Reset states
      this.patientsNotFound = false
      this.patientSearchResults = []

      this.fhirService
         .listPatients(
            this.pageForm.controls.patientSearchCriteria.controls.MRN.getRawValue()!
         )
         .subscribe({
            // There has to be a better way to do this...but it's working
            next: (value) => {
               if (value !== null) {
                  // If we get a 204 no content, there's not going to be anything in the body
                  this.patientSearchResults = value.slice(0)
               } else {
                  this.patientsNotFound = true
               }
            },
            error: (err) =>
               this.notificationService.new(
                  NotificationType.Error,
                  'An error occurred',
                  err
               ),
         })
   }

   searchForEncounter() {
      this.encountersNotFound = false
      this.encounterSearchResults = []
      let patientFhirID =
         this.pageForm.controls.currentSelections.controls.patientFhirID.getRawValue()!
      this.fhirService.listEncounters(patientFhirID).subscribe({
         next: (value) => {
            if (value !== null) {
               this.encounterSearchResults = value.slice(0)
            } else {
               this.encountersNotFound = true
            }
         },
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'An error occurred',
               err
            ),
      })
   }

   searchForProcedure() {
      this.proceduresNotFound = false
      this.procedureSearchResults = []
      let patientFhirID =
         this.pageForm.controls.currentSelections.controls.patientFhirID.getRawValue()!
      this.fhirService.listProcedures(patientFhirID).subscribe({
         next: (value) => {
            if (value !== null) {
               this.procedureSearchResults = value.slice(0)
            } else {
               this.proceduresNotFound = true
            }
         },
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'An error occurred',
               err
            ),
      })
   }

   createAndNavigateToCaseDetail() {
      let inputs: CreateCaseInputs = {
         sectionID:
            this.pageForm.controls.currentSelections.controls.sectionID.getRawValue()!,
         patientFhirID:
            this.pageForm.controls.currentSelections.controls.patientFhirID.getRawValue()!,
         encounterFhirID:
            this.pageForm.controls.currentSelections.controls.encounterFhirID.getRawValue()!,
         procedureFhirID:
            this.pageForm.controls.currentSelections.controls.procedureFhirID.getRawValue(),
      }
      this.caseManagementService.create(inputs).subscribe({
         next: (createdCase) => {
            console.log(createdCase)
            this.router.navigate(['case', createdCase.createdCaseID])
         },
      })
   }
}
