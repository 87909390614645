<app-action-tab>
   <app-button
      [label]="'New Role'"
      (click)="$formIsDisplayed.set(true)"
      class="ngc-button-region"
   ></app-button>
   <app-interactive-table
      [columnConfiguration]="columns"
      [tableData]="roles?.roles!"
      [isProcessing]="$isProcessing"
      class="ngc-table-region"
   ></app-interactive-table>
</app-action-tab>
