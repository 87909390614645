<!--Section Select and New Case button-->
<div class="flex flex-row justify-between content-baseline">
   <div class="flex flex-row">
      <p class="text-2xl font-bold leading-7 text-gray-900 mr-4 py-1.5">
         Viewing Cases For
      </p>

      <select
         id="location"
         [(ngModel)]="$selectedSection"
         name="location"
         class="block rounded-md border-0 min-w-48 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
         <option [ngValue]="null">None</option>
         @for (section of accessibleSections; track $index) {
            <option [ngValue]="section.sectionID">{{ section.name }}</option>
         }
      </select>
   </div>
</div>

<div>
   <app-tab-selector
      [Tabs]="['Open Cases', 'Next Conference', 'Recently Viewed']"
      [ActiveTab]="$activeTab"
   ></app-tab-selector>
   <div>
      <div>
         @switch ($activeTab()) {
            @case ('Next Conference') {
               <app-home-tab-next-conference
                  [sectionID]="$selectedSection()"
               ></app-home-tab-next-conference>
            }
            @case ('Recently Viewed') {
               <app-home-tab-recently-viewed></app-home-tab-recently-viewed>
            }
            @case ('Open Cases') {
               <app-home-tab-open-cases
                  [sectionID]="$selectedSection()"
               ></app-home-tab-open-cases>
            }
            @default {
               <app-home-tab-open-cases
                  [sectionID]="$selectedSection()"
               ></app-home-tab-open-cases>
            }
         }
      </div>
   </div>
</div>
