<ng-container *ngIf="dataIsLoaded">
   <div
      class="relative z-30"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
   >
      <div
         class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
         (click)="$formIsDisplayed?.set(false)"
      ></div>

      <div
         class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all"
      >
         <div class="border-b border-gray-200 pb-1.5 mb-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">
               @if (isNewRecord(this.selectedCaseFailureID)) {
                  <span>New</span>
               } @else {
                  <span>Edit</span>
               }
               Failure Mode
            </h3>
         </div>

         @if (isNewRecord(this.selectedCaseFailureID)) {
            <div>
               <label class="block text-sm font-medium leading-6 text-gray-900"
                  >Select Failure Mode</label
               >

               <cdk-tree [dataSource]="dataSource!" [treeControl]="treeControl">
                  <!-- This is the tree node template for leaf nodes -->
                  <cdk-tree-node
                     *cdkTreeNodeDef="let node"
                     cdkTreeNodePadding
                     [style.display]="shouldRender(node) ? 'flex' : 'none'"
                     class="flex text-gray-800 hover:text-violet-700 font-semibold cursor-pointer"
                     (click)="setSelectedNode(node)"
                  >
                     <!--&gt;-->
                     <!-- use a disabled button to provide padding for tree leaf to align with expandable nodes-->
                     {{ node.name }}
                  </cdk-tree-node>

                  <!-- This is the tree node template for expandable nodes -->
                  <div class="flex flex-row">
                     <cdk-tree-node
                        *cdkTreeNodeDef="let node; when: hasChild"
                        cdkTreeNodePadding
                        [style.display]="shouldRender(node) ? 'flex' : 'none'"
                     >
                        <button
                           cdkTreeNodeToggle
                           [attr.aria-label]="'Toggle ' + node.name"
                           (click)="node.isExpanded = !node.isExpanded"
                           [style.visibility]="
                              node.isExpandable ? 'visible' : 'hidden'
                           "
                        >
                           @if (treeControl.isExpanded(node)) {
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 class="w-5 h-5"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                 />
                              </svg>
                           } @else {
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 class="w-5 h-5"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                                 />
                              </svg>
                           }

                           <!--<mat-icon class="mat-icon-rtl-mirror">-->
                           <!--    {{-->
                           <!--       treeControl.isExpanded(node)-->
                           <!--          ? 'expand_more'-->
                           <!--          : 'chevron_right'-->
                           <!--    }}-->
                           <!-- </mat-icon>-->
                        </button>
                        <p class="align-middle text-gray-700">
                           {{ node.name }}
                        </p>
                     </cdk-tree-node>
                  </div>
               </cdk-tree>
            </div>
         }
         <form [formGroup]="failureModeForm">
            <div class="mt-2">
               <label
                  for="failureMode"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Failure Mode</label
               >
               <div class="mt-1">
                  <!--<input-->
                  <!--   formControlName="failureModeID"-->
                  <!--   type="text"-->
                  <!--   name="note"-->
                  <!--   failureModeID="failureModeID"-->
                  <!--   autocomplete="off"-->
                  <!--   class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
                  <!--/>-->
                  <select
                     formControlName="failureModeID"
                     id="failureMode"
                     class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                     <option [ngValue]="null"></option>
                     @for (option of failureModeTaxonomy; track $index) {
                        <option [ngValue]="option.failureModeID">
                           {{ option.name }}
                        </option>
                     }
                  </select>
               </div>
            </div>

            <div class="mt-2">
               <label
                  for="note"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Comment</label
               >
               <div class="mt-1">
                  <input
                     formControlName="note"
                     type="text"
                     name="note"
                     id="note"
                     autocomplete="off"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
               </div>
            </div>
         </form>

         <div class="border-t border-gray-200 pt-1.5">
            <div class="flex justify-between">
               <div class="flex flex-row space-x-1">
                  <button
                     type="button"
                     (click)="this.$formIsDisplayed?.set(false)"
                     class="rounded-md mt-2 bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                     Cancel
                  </button>
                  @if (!isNewRecord(this.selectedCaseFailureID)) {
                     <app-button-danger
                        [label]="'Delete'"
                        (click)="delete()"
                     ></app-button-danger>
                  }
               </div>
               <button
                  type="button"
                  (click)="submit()"
                  class="float-right mt-2 inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                  Save
               </button>
            </div>
         </div>
      </div>
   </div>
</ng-container>
