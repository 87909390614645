import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../services/case-management.service'
import { MonarchCase, ReportTopic } from '../../shared/interfaces'
import { RouterLink } from '@angular/router'
import { ReportSlideOverComponent } from '../../components/report-slide-over/report-slide-over.component'
import {
   InteractiveReport,
   InteractiveReportComponent,
} from '../../components/interactive-report/interactive-report.component'
import { BooleanPipePipe } from '../../pipes/boolean-pipe.pipe'
import { ZeroDatePipePipe } from '../../pipes/zero-date.pipe'
import { HttpResponse } from '@angular/common/http'
import { downloadAsExcel } from '../../shared/functions'
import { ReportingService } from '../../services/reporting.service'

@Component({
   selector: 'app-case-list',
   standalone: true,
   imports: [
      CommonModule,
      RouterLink,
      ReportSlideOverComponent,
      InteractiveReportComponent,
      BooleanPipePipe,
      ZeroDatePipePipe,
   ],
   templateUrl: './case-report.component.html',
})
export class CaseReportComponent implements OnInit {
   constructor(
      private caseManagementService: CaseManagementService,
      private reportingService: ReportingService
   ) {}

   cases: MonarchCase[] = []
   reportConfigurationDisplayed: boolean = false
   interactiveReportDefinition: InteractiveReport = {
      columns: [
         {
            key: 'caseID',
            displayValue: 'Case ID',
            sortIndex: 0,
            isSelected: true,
            dataType: 'number',
         },
         {
            key: 'sectionName',
            displayValue: 'Section Name',
            sortIndex: 1,
            isSelected: false,
            dataType: 'string',
         },
         {
            key: 'patientName',
            displayValue: 'Patient',
            sortIndex: 2,
            isSelected: true,
            dataType: 'string',
         },
         {
            key: 'levelOfPreventability',
            displayValue: 'Level of Preventability',
            sortIndex: 3,
            isSelected: true,
            dataType: 'string',
         },
      ],
      data: undefined,
   }

   mapClinicalCaseToReport(cases: MonarchCase[]): any[] {
      let arr: any[] = []
      for (let clinicalCase of cases) {
         let o = {
            caseID: clinicalCase.caseID,
            sectionName: clinicalCase.sectionName,
            patientName: clinicalCase.patientName,
            patientMRN: clinicalCase.patientMRN,
            levelOfPreventability: clinicalCase.levelOfPreventability,
            categorization: clinicalCase.categorization,
         }
         arr.push(o)
      }
      return arr
   }

   download() {
      this.reportingService.downloadReportAsExcel(ReportTopic.Case).subscribe({
         next: (value) => {
            if (value instanceof HttpResponse) {
               downloadAsExcel(value, 'CaseReport')
            }
         },
      })
   }

   ngOnInit() {
      this.caseManagementService.getCasesForSections([]).subscribe({
         next: (value) => {
            this.cases = value
            this.interactiveReportDefinition.data =
               this.mapClinicalCaseToReport(value)
            // (this.caseList = value)},
         },
      })
   }
}
