@if (dataIsLoaded) {
   <div class="sticky top-0 backdrop-blur bg-white bg-opacity-90">
      @if (this.clinicalCase.isOpen && this.clinicalCase.canClose) {
         <div class="rounded-md bg-green-50 p-4 my-2">
            <div class="flex">
               <div class="flex-shrink-0">
                  <svg
                     class="h-5 w-5 text-green-400"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     aria-hidden="true"
                  >
                     <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clip-rule="evenodd"
                     />
                  </svg>
               </div>
               <div class="ml-3">
                  <h3 class="text-sm font-medium text-green-800">
                     Ready to Close
                  </h3>
                  <div class="mt-2 text-sm text-green-700">
                     <p>
                        This Case has met the necessary criteria to Close.
                        Closing a Case indicates that no further action is
                        needed and the Case is ready for inclusion in all
                        reporting. The Case will be removed from the "In
                        Progress" list on your Home page.
                     </p>
                  </div>
                  <div class="mt-2">
                     <div class="flex flex-row-reverse w-full">
                        <button
                           type="button"
                           (click)="closeCase()"
                           class="rounded-md bg-green-200 px-2 py-1.5 text-sm font-medium text-green-900 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                           Close Case Now
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!--  Only show this banner if case is closed-->
      } @else if (!this.clinicalCase.isOpen && this.clinicalCase.canClose) {
         <div class="rounded-md bg-blue-50 p-4 my-2">
            <div class="flex">
               <div class="flex-shrink-0">
                  <svg
                     class="h-5 w-5 text-blue-400"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     aria-hidden="true"
                  >
                     <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                        clip-rule="evenodd"
                     />
                  </svg>
               </div>
               <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm text-blue-700">
                     This Case has been closed and is read-only
                  </p>
               </div>
            </div>
         </div>
      }

      <div class="lg:flex lg:items-center lg:justify-between pb-0.5">
         <!--Patient Header-->
         <div class="min-w-0 flex-1">
            <h2
               class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
            >
               {{ clinicalCase.patientName }}
            </h2>
            <div
               class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6"
            >
               <div class="mt-2 flex items-center text-sm text-gray-500">
                  MRN:
                  <span class="ml-0.5 font-medium">{{
                     clinicalCase.patientMRN
                  }}</span>
               </div>
               <div class="mt-2 flex items-center text-sm text-gray-500">
                  DOB:
                  <span class="ml-0.5 font-medium">{{
                     clinicalCase.patientDOB | date
                  }}</span>
               </div>
               <!--<div class="mt-2 flex items-center text-sm text-gray-500">-->
               <!--   Encounter:-->
               <!--   <span class="ml-0.5 font-medium"-->
               <!--      >{{ clinicalCase.encounter.started | date }} &ndash;-->
               <!--      {{ clinicalCase.encounter.ended | date }}</span-->
               <!--   >-->
               <!--</div>-->
               <div class="mt-2 flex items-center text-sm text-gray-500">
                  Case ID:
                  <span class="ml-0.5 font-medium">{{
                     clinicalCase.caseID
                  }}</span>
               </div>
            </div>
         </div>
      </div>

      <app-tab-selector
         [Tabs]="caseNavigationTabsArray"
         [ActiveTab]="activeTab$"
      ></app-tab-selector>
   </div>

   <!--Main content-->
   <form [formGroup]="caseForm">
      @switch (activeTab$()) {
         @case (caseNavigationTabList.OVERVIEW) {
            <div id="caseOverview">
               <!--Section Header with Actions-->
               <div class="flex justify-between py-2 border-b border-gray-100">
                  <div class="flex items-center">
                     <p
                        class="text-base font-semibold leading-6 text-gray-900 align-middle"
                     >
                        {{ caseNavigationTabList.OVERVIEW }}
                     </p>
                  </div>
                  <div>
                     @if (clinicalCase.isOpen) {
                        <button
                           type="button"
                           (click)="saveChanges()"
                           class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                           Save
                        </button>
                     }
                  </div>
               </div>

               <div class="flex flex-row gap-x-6">
                  <!--Left Side-->
                  <div class="w-2/3">
                     <!--Diagnosis-->
                     <div class="mt-2">
                        <label
                           for="diagnosis"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Diagnosis</label
                        >
                        <div class="mt-2">
                           <input
                              formControlName="diagnosis"
                              type="text"
                              name="diagnosis"
                              id="diagnosis"
                              class="block w-full rounded-md border-0 py-1.w5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                        </div>
                     </div>

                     <!--Summary-->
                     <div class="mt-2">
                        <label
                           for="summary"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Summary of Events</label
                        >
                        <div class="mt-2">
                           <textarea
                              formControlName="summary"
                              rows="8"
                              name="summary"
                              id="summary"
                              class="block w-full whitespace-pre-line rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           ></textarea>
                        </div>
                     </div>
                  </div>

                  <!--Right Side-->
                  <div class="w-1/3">
                     <div class="mt-2">
                        <label
                           for="conference"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Conference</label
                        >
                        <select
                           formControlName="conferenceID"
                           id="conference"
                           name="conference"
                           class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                           <option [ngValue]="null"></option>
                           @for (conf of conferenceDateOptions; track $index) {
                              <option [ngValue]="conf.conferenceID">
                                 {{ conf.startTime | date: 'short' }}
                              </option>
                           }
                        </select>
                     </div>

                     <div class="mt-2">
                        <label
                           for="categorization"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Categorization</label
                        >
                        <select
                           formControlName="categorizationID"
                           id="categorization"
                           name="categorization"
                           class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                           <option [ngValue]="null"></option>
                           @for (
                              categorization of capabilityOptions;
                              track $index
                           ) {
                              <option [ngValue]="categorization.id">
                                 {{ categorization.name }}:
                                 {{ categorization.description }}
                              </option>
                           }
                        </select>
                     </div>

                     <div class="mt-2">
                        <label
                           for="changeInClinicalStatus"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Change in Clinical Status (After Arrival)</label
                        >
                        <div class="mt-2">
                           <select
                              formControlName="changeInClinicalStatusID"
                              id="changeInClinicalStatus"
                              name="changeInClinicalStatus"
                              class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           >
                              <option [ngValue]="null"></option>
                              @for (
                                 change of clinicalStatusOptions;
                                 track $index
                              ) {
                                 <option [ngValue]="change.id">
                                    {{ change.name }}
                                 </option>
                              }
                           </select>
                        </div>
                     </div>

                     <div class="mt-2">
                        <label
                           for="levelOfPreventability"
                           class="block text-sm font-medium leading-6 text-gray-900"
                           >Preventability</label
                        >
                        <div class="mt-2">
                           <select
                              formControlName="levelOfPreventabilityID"
                              id="levelOfPreventability"
                              name="levelOfPreventability"
                              class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           >
                              <option [ngValue]="null"></option>
                              @for (
                                 preventability of preventabilityOptions;
                                 track $index
                              ) {
                                 <option [ngValue]="preventability.id">
                                    {{ preventability.name }}
                                 </option>
                              }
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
         @case (caseNavigationTabList.ENCOUNTERS) {
            <app-tab-encounters
               [caseID]="caseID"
               [patientFhirID]="clinicalCase.patientFhirID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-encounters>
         }
         @case (caseNavigationTabList.PROCEDURES) {
            <app-tab-procedures
               [caseID]="caseID"
               [patientFhirID]="clinicalCase.patientFhirID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-procedures>
         }
         @case (caseNavigationTabList.STAFF) {
            <app-tab-staff [caseID]="caseID"> </app-tab-staff>

            <!--<div id="staff">-->
            <!--   <div class="flex space-x-8">-->
            <!--      <div class="w-full">-->
            <!--         &lt;!&ndash;Section Header&ndash;&gt;-->
            <!--         <div-->
            <!--            class="flex justify-between py-2 border-b border-gray-100"-->
            <!--         >-->
            <!--            &lt;!&ndash;Title&ndash;&gt;-->
            <!--            <div class="flex items-center">-->
            <!--               <p-->
            <!--                  class="text-base font-semibold leading-6 text-gray-900 align-middle"-->
            <!--               >-->
            <!--                  Staff-->
            <!--               </p>-->
            <!--            </div>-->

            <!--            &lt;!&ndash;Button Group&ndash;&gt;-->
            <!--            @if (clinicalCase.isOpen) {-->
            <!--               <button-->
            <!--                  type="button"-->
            <!--                  class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"-->
            <!--               >-->
            <!--                  Add Linked Staff-->
            <!--               </button>-->
            <!--            }-->
            <!--         </div>-->
            <!--      </div>-->
            <!--   </div>-->
            <!--</div>-->
         }
         @case (caseNavigationTabList.ADVERSE_EVENTS) {
            <app-tab-adverse-events
               [caseID]="caseID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-adverse-events>
         }
         @case (caseNavigationTabList.OUTCOMES) {
            <app-tab-case-outcomes
               [caseID]="caseID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-case-outcomes>
         }
         @case (caseNavigationTabList.DISCUSSION) {
            <div id="discussionTab">
               <div class="flex justify-between py-2 border-b border-gray-100">
                  <div class="flex items-center">
                     <p
                        class="text-base font-semibold leading-6 text-gray-900 align-middle"
                     >
                        {{ caseNavigationTabList.DISCUSSION }}
                     </p>
                  </div>
                  <div>
                     @if (clinicalCase.isOpen) {
                        <button
                           type="button"
                           (click)="saveChanges()"
                           class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                           Save
                        </button>
                     }
                  </div>
               </div>
               <div>
                  <label
                     for="discussion"
                     class="block pt-1.5 text-sm font-medium leading-6 text-gray-900"
                     >Conference Discussion / Summary</label
                  >
                  <div class="mt-2">
                     <textarea
                        rows="12"
                        name="discussion"
                        id="discussion"
                        formControlName="discussion"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     ></textarea>
                  </div>
               </div>
            </div>
         }
         @case (caseNavigationTabList.FAILURE_MODES) {
            <app-tab-failures-component
               [caseID]="caseID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-failures-component>
         }
         @case (caseNavigationTabList.ACTION_ITEMS) {
            <app-tab-action-items
               [caseID]="caseID"
               [allowEditing]="clinicalCase.isOpen"
            >
            </app-tab-action-items>
         }
         @case (caseNavigationTabList.DOCUMENTS) {
            <app-tab-documents
               [caseID]="caseID"
               [allowEditing]="clinicalCase.isOpen"
            ></app-tab-documents>
         }
         @case (caseNavigationTabList.ROUTING) {
            <div id="routingTab">
               <div class="flex space-x-8">
                  <div class="w-full">
                     <!--Section Header-->
                     <div
                        class="flex justify-between py-2 border-b border-gray-100"
                     >
                        <!--Title-->
                        <div class="flex items-center">
                           <p
                              class="text-base font-semibold leading-6 text-gray-700 align-middle"
                           >
                              Routing
                           </p>
                        </div>

                        @if (clinicalCase.isOpen) {
                           <button
                              type="button"
                              class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                           >
                              Route for Additional Review
                           </button>
                        }
                     </div>
                     <app-no-data-found
                        title="Coming Soon!"
                     ></app-no-data-found>
                  </div>
               </div>
            </div>
         }
         @case (caseNavigationTabList.AUDIT) {
            <div id="auditTab" class="mt-2">
               <app-tab-audit-trail
                  [caseID]="this.caseID"
               ></app-tab-audit-trail>
            </div>
         }
      }
   </form>
}
