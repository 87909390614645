import { EventEmitter, Injectable, OnInit } from '@angular/core'

@Injectable({
   providedIn: 'root',
})
export class ProgressBarService implements OnInit {
   constructor() {
      this.isDisplayed = false
   }

   isDisplayed: boolean

   ngOnInit() {
      console.log('Progress bar initialized. Displayed?', this.isDisplayed)
   }
}
