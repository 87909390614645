<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
   <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="text-2xl text-center text-orange-400 font-semibold">
         Monarch.
      </h2>
      <p class="text-center text-gray-800 my-2">Activate your account</p>
   </div>

   <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" [formGroup]="accountActivationForm">
         <div>
            <label
               for="activationToken"
               autofocus
               class="block text-sm font-medium leading-6 text-gray-900"
               >Activation Token</label
            >
            <div class="mt-1">
               <input
                  formControlName="activationToken"
                  tabindex="1"
                  id="activationToken"
                  name="activationToken"
                  type="text"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
               />
            </div>
         </div>
         <div class="mt-2">
            <label
               for="email"
               autofocus
               class="block text-sm font-medium leading-6 text-gray-900"
               >Email</label
            >
            <div class="mt-1">
               <input
                  formControlName="email"
                  tabindex="1"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none"
               />
            </div>
         </div>
         <div class="mt-2">
            <label
               for="password"
               class="block text-sm font-medium leading-6 text-gray-900"
               >New Password</label
            >
            <div class="mt-1">
               <input
                  formControlName="newPassword"
                  tabindex="1"
                  id="password"
                  name="password"
                  type="password"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
               />
            </div>
         </div>
         <div class="mt-2">
            <label
               for="confirmPassword"
               class="block text-sm font-medium leading-6 text-gray-900"
               >Confirm New Password</label
            >
            <div class="mt-1">
               <input
                  formControlName="confirmPassword"
                  tabindex="1"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ng-invalid:border-pink-500 ng-invalid:text-pink-600"
               />
            </div>
         </div>

         @if (accountActivationForm.pristine) {
            <div></div>
         } @else if (accountActivationForm.hasError('passwordsMismatch')) {
            <div class="rounded-md bg-red-50 p-4 mt-4">
               <div class="flex justify-center">
                  <div class="flex-shrink-0">
                     <svg
                        class="h-5 w-5 text-red-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                     >
                        <path
                           fill-rule="evenodd"
                           d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                           clip-rule="evenodd"
                        />
                     </svg>
                  </div>
                  <div class="ml-3">
                     <h3 class="text-sm font-medium text-red-800">
                        Passwords do not match
                     </h3>
                  </div>
               </div>
            </div>
         } @else {
            <button
               (click)="activateAccountWithPasswordChange()"
               tabindex="3"
               type="submit"
               [disabled]="accountActivationForm.hasError('passwordsMismatch')"
               class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
               Submit
            </button>
         }
      </form>
   </div>
</div>
