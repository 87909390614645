import { Component, OnInit, signal, WritableSignal } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CaseManagementService } from '../../services/case-management.service'
import { Capability, Conference, MonarchCase } from '../../shared/interfaces'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { forkJoin } from 'rxjs'
import {
   NotificationService,
   NotificationType,
} from '../../services/notification.service'
import { ProgressBarService } from '../../services/progress-bar.service'
import { FileUploadFormComponent } from './tab-documents/file-upload-form.component'
import { ActionItemFormComponent } from './tab-action-items/action-item-form.component'
import { FailureModeFormComponent } from './tab-failures-modes/failure-mode-form.component'
import { OutcomeFormComponent } from './tab-outcomes/outcome-form.component'
import { AdverseEventFormComponent } from './tab-adverse-events/adverse-event-form.component'
import {
   DatePipe,
   NgClass,
   NgFor,
   NgIf,
   NgSwitch,
   NgSwitchCase,
} from '@angular/common'
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component'
import { AuditTrailFeedComponent } from '../../components/audit-trail-feed/audit-trail-feed.component'
import { LoggingService } from '../../services/logging.service'
import { FloatingOptionsMenuComponent } from '../../components/floating-options-menu/floating-options-menu.component'
import { TabCaseActionItems } from './tab-action-items/tab-case-action-items.component'
import { TabCaseFailureModes } from './tab-failures-modes/tab-case-failure-modes.component'
import { TabCaseAdverseEvents } from './tab-adverse-events/tab-case-adverse-events.component'
import { TabCaseOutcomes } from './tab-outcomes/tab-case-outcomes.component'
import { TabEncountersComponent } from './tab-encounters/tab-encounters.component'
import { TabProceduresComponent } from './tab-procedures/tab-procedures.component'
import { TabDocumentsComponent } from './tab-documents/tab-documents.component'
import { ConferenceService } from '../../services/conference.service'
import { AdminService } from '../../services/admin.service'
import { TabSelectorComponent } from '../../components/tab-selector/tab-selector.component'
import { caseNavigationTabList } from '../../shared/enums'
import { TabStaffComponent } from './tab-staff/tab-staff.component'
import { NoDataFoundComponent } from '../../shared/components/empty-state/no-data-found/no-data-found.component'
import { TabAuditTrailComponent } from './tab-audit-trail/tab-audit-trail.component'

@Component({
   selector: 'app-case-editor',
   templateUrl: './case-editor-page.component.html',
   standalone: true,
   imports: [
      NgIf,
      NgFor,
      NgClass,
      ReactiveFormsModule,
      NgSwitch,
      NgSwitchCase,
      AdverseEventFormComponent,
      OutcomeFormComponent,
      FailureModeFormComponent,
      ActionItemFormComponent,
      FileUploadFormComponent,
      DatePipe,
      SimpleListComponent,
      AuditTrailFeedComponent,
      FloatingOptionsMenuComponent,
      TabCaseActionItems,
      TabCaseFailureModes,
      TabCaseAdverseEvents,
      TabCaseOutcomes,
      TabEncountersComponent,
      TabProceduresComponent,
      TabDocumentsComponent,
      TabSelectorComponent,
      TabStaffComponent,
      NoDataFoundComponent,
      TabAuditTrailComponent,
   ],
})
export class CaseEditorPageComponent implements OnInit {
   caseID: number = 0
   caseForm: FormGroup
   dataIsLoaded = false
   clinicalCase: MonarchCase = <MonarchCase>{}
   activeTab$: WritableSignal<string> = signal(caseNavigationTabList.OVERVIEW)
   caseNavigationTabsArray: string[] = Object.values(caseNavigationTabList)

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private caseManagementService: CaseManagementService,
      private notificationService: NotificationService,
      private progressBarService: ProgressBarService,
      private logger: LoggingService,
      private conferenceService: ConferenceService,
      private adminService: AdminService
   ) {
      this.caseForm = new FormGroup({
         diagnosis: new FormControl(''),
         summary: new FormControl(''),
         conferenceID: new FormControl(),
         categorizationID: new FormControl(),
         changeInClinicalStatusID: new FormControl(),
         levelOfPreventabilityID: new FormControl(),
         searchTags: new FormControl(''),
         discussion: new FormControl(''),
      })
      this.progressBarService.isDisplayed = true
   }

   ngOnInit() {
      this.fetchCase()
      this.fetchDropdownOptions()
   }

   // Fetches the ClinicalCase specified by the ID in the route parameter
   // Note: If we ever re-use this component without re-navigating (e.g case routing), will need to use the observable ActivatedRoute instead
   fetchCase() {
      this.caseID = parseInt(<string>this.route.snapshot.paramMap.get('id'))
      if (isNaN(this.caseID)) {
         console.log('Unable to identify Case ID') //TODO: Show an alert
      }
      this.caseManagementService.getCase(this.caseID).subscribe({
         next: (value) => {
            this.clinicalCase = value

            this.caseForm.patchValue({
               diagnosis: this.clinicalCase.diagnosis,
               summary: this.clinicalCase.summary,
               categorizationID: this.clinicalCase.categorizationID,
               changeInClinicalStatusID:
                  this.clinicalCase.changeInClinicalStatusID,
               levelOfPreventabilityID:
                  this.clinicalCase.levelOfPreventabilityID,
               discussion: this.clinicalCase.discussion,
               conferenceID: this.clinicalCase.conferenceID,
            })
         },
         complete: () => (this.dataIsLoaded = true),
      })
   }

   saveChanges() {
      this.caseManagementService
         .updateCase(this.clinicalCase.caseID, this.caseForm.getRawValue())
         .subscribe({
            complete: () =>
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully Saved',
                  ''
               ),
         })
   }

   // MARK: Overview
   capabilityOptions: Capability[] = []
   clinicalStatusOptions: Capability[] = []
   preventabilityOptions: Capability[] = []
   conferenceDateOptions: Conference[] = []

   fetchDropdownOptions() {
      forkJoin({
         levelOfPreventability: this.adminService.getCapabilities(
            'PreventabilityLevel'
         ),
         caseCategorization: this.adminService.getCapabilities('CaseCategory'),
         changeInClinicalStatus: this.adminService.getCapabilities(
            'ChangeInClinicalStatus'
         ),
         conferenceOptions: this.conferenceService.getConferencesForSection(1), // FIXME - P2: Hard-coded value
      }).subscribe({
         next: (value) => {
            this.capabilityOptions = value.caseCategorization
            this.clinicalStatusOptions = value.changeInClinicalStatus
            this.preventabilityOptions = value.levelOfPreventability
            this.conferenceDateOptions = value.conferenceOptions
         },
         complete: () => (this.progressBarService.isDisplayed = false),
      })
   }

   closeCase() {
      this.caseManagementService.closeCase(this.caseID).subscribe({
         next: (value) => {
            this.router.navigate(['home'])
            this.notificationService.new(
               NotificationType.OK,
               'Case closed successfully'
            )
         },
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'There was an issue closing this case'
            ),
      })
   }

   protected readonly caseNavigationTabList = caseNavigationTabList
}

// MARK: Supporting content
