import { Component, forwardRef, Input } from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import {
   ControlValueAccessor,
   FormsModule,
   NG_VALUE_ACCESSOR,
} from '@angular/forms'

@Component({
   selector: 'app-input-date',
   standalone: true,
   imports: [FormsModule],
   templateUrl: './input-date.component.html',
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => InputDateComponent), // replace name as appropriate
         multi: true,
      },
   ],
})
export class InputDateComponent implements ControlValueAccessor {
   @Input({ required: true }) label: string = ''
   @Input({ required: true }) name: string = ''
   value: Date | undefined
   generatedID: string = 'input_date_'
   constructor() {
      this.generatedID = this.generatedID + uuidv4()
   }

   // The method set when registerOnChange is called
   onChange: any = () => {}
   // The method set when registerOnTouched is called
   onTouched: any = () => {}

   // Write a new value to the element
   writeValue(value: any): void {
      this.value = value
   }

   // Save a callback function that should be called when the control's value changes in the UI
   registerOnChange(fn: any): void {
      this.onChange = fn
   }

   // Save a callback function that should be called when the control is touched
   registerOnTouched(fn: any): void {
      this.onTouched = fn
   }

   // Set whether the control is in a disabled state
   setDisabledState?(isDisabled: boolean): void {
      // TODO: Handle the disabled state here if needed
   }

   // Method to call when the input value changes
   handleChange(event: any): void {
      this.value = event.target.value
      this.onChange(this.value)
   }

   // Method to call when the input is touched
   handleBlur(): void {
      this.onTouched()
   }
}
