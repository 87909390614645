<app-action-tab [$formIsDisplayed]="$formIsDisplayed">
   <app-button
      [label]="'New Complication'"
      (click)="$formIsDisplayed.set(true)"
      class="ngc-button-region"
   ></app-button>
   <app-interactive-table
      [columnConfiguration]="columns"
      [tableData]="complicationOptions"
      [isProcessing]="$isProcessing"
      class="ngc-table-region"
   ></app-interactive-table>
</app-action-tab>
