import { Component, Input } from '@angular/core'
import { NotificationType } from '../../services/notification.service'
import { NgClass } from '@angular/common'

@Component({
   selector: 'app-notification',
   templateUrl: './notification.component.html',
   standalone: true,
   imports: [NgClass],
})
export class NotificationComponent {
   @Input({ required: true }) type!: NotificationType
   @Input({ required: true }) title!: string
   @Input() body: string = ''

   getNotificationClasses(type: NotificationType): any {
      switch (type) {
         case NotificationType.OK:
            return { 'bg-emerald-100 text-emerald-900 ': true }
         case NotificationType.Info:
            return { 'bg-blue-100 text-blue-900': true }
         case NotificationType.Alert:
            return { 'bg-yellow-100 text-yellow-700': true }
         case NotificationType.Error:
            return { 'bg-red-100 text-red-800': true }
         default:
            return {}
      }
   }

   getSVGPath(type: NotificationType): string {
      switch (type) {
         case NotificationType.OK: // Checkmark
            return 'M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
         case NotificationType.Info: // i within a circle
            return 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z'
         case NotificationType.Alert: // yield sign
            return 'M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z'
         case NotificationType.Error: // x
            return 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z'
         default:
            return ''
      }
   }
}
