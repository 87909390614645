<app-modal [title]="'Link New Encounter'" [$isDisplayed]="$formIsDisplayed">
   <app-interactive-table
      [columnConfiguration]="encounterSearchResultColumns"
      [tableData]="encounterSearchResults"
      [enableSelection]="true"
      [isProcessing]="signal(this.searchInProgress)"
      (selectionEvent)="this.selectedEncounterFhirID = $event.FHIRID"
   ></app-interactive-table>
   <div class="ngc-footer-right">
      <app-button [label]="'Save'" (click)="create()"></app-button>
   </div>
</app-modal>
