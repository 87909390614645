import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { UserService } from '../services/user.service'
import { LoggingService } from '../services/logging.service'

// FIXME / CRITICAL: Need to NOT trigger the interceptor on /auth/token, because that's an unprotected route and
// If it's left guarded then the user will never be able to authenticate
export const authInterceptor: HttpInterceptorFn = (req, next) => {
   let accessToken = inject(UserService).getAccessToken()
   let refreshToken = inject(UserService).getRefreshToken()
   let logger = inject(LoggingService)

   logger.debug('Authentication interceptor triggered')

   if (!(accessToken || refreshToken)) {
      logger.error(
         'Unable to fetch accessToken or refreshToken from localStorage'
      )
      return next(req)
   }

   let headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken!,
      'X-Monarch-Refresh': refreshToken!,
   })

   let requestWithHeader = req.clone({
      headers: headers,
   })

   return next(requestWithHeader)
}
