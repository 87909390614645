<main>
   <!-- Global notification region, top right corner-->
   <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 z-40"
      id="globalNotificationRegion"
   >
      <div
         *ngIf="notificationIsDisplayed"
         class="flex w-full h-full flex-col space-y-4"
      >
         <app-notification
            [type]="displayedNotification.type"
            [title]="displayedNotification.title"
            class="w-full max-w-sm overflow-hidden fixed top-5 right-5 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
         >
         </app-notification>
      </div>
   </div>
   @if (this.userService.isLoggedIn()) {
      <div>
         <ng-container>
            <app-nav-menu></app-nav-menu>
            <div class="lg:pl-72">
               <app-nav-bar></app-nav-bar>
               <div class="px-4 sm:px-6 lg:px-8">
                  <router-outlet></router-outlet>
               </div>
            </div>
         </ng-container>
      </div>
   } @else if (this.isRequestingAccountActivation) {
      <app-user-activation></app-user-activation>
   } @else {
      <div class="px-4 sm:px-6 lg:px-8">
         <app-login></app-login>
      </div>
   }
</main>
