import { Component, inject, Input, OnInit, WritableSignal } from '@angular/core'
import { DangerComponent } from '../../shared/components/button/danger/danger.component'
import {
   FormControl,
   FormGroup,
   FormsModule,
   ReactiveFormsModule,
} from '@angular/forms'
import { InputNumberComponent } from '../../shared/components/controls/input-number/input-number.component'
import { InputTextComponent } from '../../shared/components/controls/input-text/input-text.component'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { ModalComponent } from '../../shared/components/modal/modal.component'
import { UserService } from '../../services/user.service'
import {
   NotificationService,
   NotificationType,
} from '../../services/notification.service'

@Component({
   selector: 'app-user-form',
   standalone: true,
   imports: [
      DangerComponent,
      FormsModule,
      ReactiveFormsModule,
      InputNumberComponent,
      InputTextComponent,
      ButtonComponent,
      ModalComponent,
   ],
   templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
   constructor(
      private userService: UserService,
      private notificationService: NotificationService
   ) {}

   @Input({ required: true }) $formIsDisplayed!: WritableSignal<boolean>
   userForm = new FormGroup({
      userID: new FormControl(null),
      email: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isInstanceAdmin: new FormControl(false),
   })

   ngOnInit() {
      this.userForm.events.subscribe({
         next: (value) => console.log(value),
      })
   }

   submit() {
      this.userService
         .createUser(
            this.userForm.controls.email.value!,
            this.userForm.controls.firstName.value!
         )
         .subscribe({
            next: (value) => console.log(value),
            error: (error) =>
               this.notificationService.new(NotificationType.Error, error),
         })
   }

   test() {
      console.log(this.userForm.controls)
   }
}
