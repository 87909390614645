import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { CaseLinkedEncounter, MonarchCaseTab } from '../../../shared/interfaces'
import {
   BadgeTheme,
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { ModalComponent } from '../../../shared/components/modal/modal.component'
import { InputTextComponent } from '../../../shared/components/controls/input-text/input-text.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LinkedEncounterFormComponent } from './linked-encounter-form.component'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'

@Component({
   selector: 'app-tab-encounters',
   standalone: true,
   imports: [
      CommonModule,
      SimpleListComponent,
      ModalComponent,
      InputTextComponent,
      FormsModule,
      ReactiveFormsModule,
      LinkedEncounterFormComponent,
   ],
   templateUrl: './tab-encounters.component.html',
})
export class TabEncountersComponent implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) patientFhirID: string = ''
   @Input({ required: true }) allowEditing: boolean = false // Would be controlled by a security point
   @Input() $formIsDisplayed: WritableSignal<boolean> = signal(false)

   $caseLinkedEncounters: WritableSignal<CaseLinkedEncounter[]> = signal([])
   linkedEncounterDisplayList: SimpleList = []

   constructor(
      private caseManagementService: CaseManagementService,
      private notificationService: NotificationService
   ) {
      effect(() => {
         this.linkedEncounterDisplayList = this.$caseLinkedEncounters().map(
            (x: CaseLinkedEncounter) => mapLinkedEncounterToListItem(x)
         )
      })
   }

   ngOnInit() {
      this.caseManagementService.getRelatedEncounters(this.caseID).subscribe({
         next: (value) => this.$caseLinkedEncounters.set(value),
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'We encountered an unexpected error',
               err
            ),
      })
   }
}

function mapLinkedEncounterToListItem(e: CaseLinkedEncounter): SimpleListItem {
   //TODO: Sloppy
   let start = new Date(e.startedAt).toLocaleDateString('en')
   let end = new Date(e.endedAt).toLocaleDateString('en')
   let item: SimpleListItem = {
      id: e.encounterIdentifier,
      title: e.type + ' encounter from ' + start + ' to ' + end,
      description: e.note,
      propertyPairs: [
         {
            key: 'CSN',
            display: 'CSN',
            value: e.encounterIdentifier.toString(),
         },
         { key: 'STATUS', display: 'Status', value: e.status },
      ],
      badges: [],
   }
   if (e.isPrimary) {
      let primaryBadge = {
         title: 'Primary Encounter',
         theme: BadgeTheme.GREEN,
      }
      item.badges?.push(primaryBadge)
   }
   return item
}
