import { Component, inject, Injectable, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { CaseAuditEvent } from '../../../shared/interfaces'

@Component({
   selector: 'app-tab-audit-trail',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './tab-audit-trail.component.html',
})
export class TabAuditTrailComponent implements OnInit {
   @Input({ required: true }) caseID!: number
   auditEvents: CaseAuditEvent[] = []
   constructor(private caseManagementService: CaseManagementService) {}

   ngOnInit(): void {
      this.caseManagementService.getAuditTrail(this.caseID).subscribe({
         next: (value) => (this.auditEvents = value.slice(0)),
      })
   }
}
