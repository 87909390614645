<app-action-tab>
   <app-button
      [label]="'Add User to Section'"
      (click)="$formIsDisplayed.set(true)"
      class="ngc-button-region"
   ></app-button>
   <app-interactive-table
      [columnConfiguration]="columns"
      [tableData]="userPermissions"
      [isProcessing]="$isProcessing"
      class="ngc-table-region"
   ></app-interactive-table>
   <app-section-user-security-form
      [$formIsDisplayed]="$formIsDisplayed"
      class="ngc-form-region"
   ></app-section-user-security-form>
</app-action-tab>
