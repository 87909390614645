import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-tab-outcomes',
  standalone: true,
  imports: [],
  templateUrl: './admin-tab-outcomes.component.html'
})
export class AdminTabOutcomesComponent {

}
