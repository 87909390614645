import { Component, OnInit, signal, WritableSignal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdminService } from '../../services/admin.service'
import { User } from '../../shared/interfaces'
import { BooleanPipePipe } from '../../pipes/boolean-pipe.pipe'
import { RouterLink } from '@angular/router'
import { ZeroDatePipePipe } from '../../pipes/zero-date.pipe'
import { LabelComponent } from '../../shared/components/badges/label/label.component'

@Component({
   selector: 'app-user-security-report',
   standalone: true,
   imports: [
      CommonModule,
      BooleanPipePipe,
      RouterLink,
      ZeroDatePipePipe,
      LabelComponent,
   ],
   templateUrl: './user-security-report.component.html',
})
export class UserSecurityReportComponent implements OnInit {
   $userList: WritableSignal<User[]> = signal([])
   constructor(private adminService: AdminService) {}

   ngOnInit() {
      this.getUsers()
   }

   getUsers() {
      this.adminService.getManyUsers().subscribe({
         next: (value) => this.$userList.set(value),
      })
   }
}
