import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import {
   Complication,
   InteractiveTableColumn,
} from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'

@Component({
   selector: 'app-admin-tab-complications',
   standalone: true,
   imports: [InteractiveTableComponent, ActionTabComponent, ButtonComponent],
   templateUrl: './admin-tab-complications.component.html',
})
export class AdminTabComplicationsComponent implements OnInit {
   adminService = inject(AdminService)
   complicationOptions: Complication[] = []
   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   $isProcessing: WritableSignal<boolean> = signal(false)

   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getAllComplicationOptions().subscribe({
         next: (value) => (this.complicationOptions = value.slice(0)),
         complete: () => this.$isProcessing.set(false),
      })
   }

   columns: InteractiveTableColumn[] = [
      {
         key: 'id',
         displayValue: 'Complication ID',
         sortIndex: 0,
         dataType: 'number',
         isVisible: true,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'name',
         displayValue: 'Complication',
         sortIndex: 2,
         dataType: 'string',
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'category',
         displayValue: 'Category',
         sortIndex: 1,
         dataType: 'number',
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'description',
         displayValue: 'Definition',
         sortIndex: 3,
         dataType: 'string',
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
   ]
}
