<form name="fileUploadDropzone">
   <div class="space-y-2">
      <div
         [ngClass]="dropzoneIsFocused ? 'border-indigo-700' : 'border-gray-400'"
         class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
            class="mx-auto w-12 h-12 text-indigo-700"
         >
            <path
               stroke-linecap="round"
               stroke-linejoin="round"
               d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
         </svg>
         <span class="mt-2 block text-sm text-slate-500"
            >Drag and drop document(s) here to upload</span
         >
      </div>

      <!--Divider-->
      <div class="relative">
         <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
         </div>
         <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">or</span>
         </div>
      </div>

      <div>
         <input
            type="file"
            name="file"
            multiple
            class="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-indigo-700 hover:file:bg-violet-100"
         />
      </div>

      <!--TODO: Enable button only when files > 0-->
      <div class="w-full flex flex-row-reverse">
         <button
            type="button"
            (click)="uploadFile()"
            class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
         >
            Upload
         </button>
      </div>
   </div>
</form>
