<div class="py-1.5 flex flex-row justify-between">
   <div>
      <ng-content select=".ngc-header-region"></ng-content>
   </div>
   <div>
      <ng-content select=".ngc-button-region"></ng-content>
   </div>
</div>

<div class="flex flex-row">
   <div class="w-full overflow-auto">
      <ng-content select=".ngc-table-region"></ng-content>
   </div>
   @if ($formIsDisplayed()) {
      <div class="ml-2 w-2/3">
         <ng-content select=".ngc-form-region"></ng-content>
      </div>
   }
</div>
