// TODO: When a query is entered, need to push to q queryParam, so that when back button is pressed, it returns to page with the old query already populated
// TODO: When back button pressed, re-execute query or save state
// TODO: After navigating to this page from the nav-bar search, both will be highlighted and navbar will be focused. Clear nav search and focus this main search
import { Component, HostListener, OnInit } from '@angular/core'
import { NgFor } from '@angular/common'
import {
   FormArray,
   FormControl,
   FormGroup,
   FormsModule,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { CaseManagementService } from '../../services/case-management.service'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { ActivatedRoute, RouterModule } from '@angular/router'
import { UserService } from '../../services/user.service'
import { ClinicalSection, SearchResult } from '../../shared/interfaces'
import { SearchService } from '../../services/search.service'

@Component({
   selector: 'app-search',
   templateUrl: './search.component.html',
   standalone: true,
   imports: [
      ReactiveFormsModule,
      NgFor,
      ButtonComponent,
      FormsModule,
      RouterModule,
   ],
})
export class SearchComponent implements OnInit {
   constructor(
      private activatedRoute: ActivatedRoute,
      private userService: UserService,
      private searchService: SearchService
   ) {
      this.searchForm = new FormGroup({
         query: new FormControl('', Validators.required),
         sectionIDs: new FormArray([]),
      })
   }
   searchForm: FormGroup
   accessibleSections: ClinicalSection[] | undefined = undefined
   searchResults: SearchResult[] = []

   @HostListener('window:keyup.enter', ['$event'])
   onEnterExecuteSearch(event: KeyboardEvent) {
      event.preventDefault() // Don't submit the page onEnter
      this.search()
   }

   ngOnInit() {
      this.accessibleSections = this.userService.getAccessibleSections()

      this.accessibleSections.forEach(() =>
         (this.searchForm!.get('sectionIDs') as FormArray).push(
            new FormControl(true) // Assume the user wants to search across all accessible sections and check the checkbox
         )
      )

      // Observe the URL to populate query term when search has initiated from another page or a web link
      this.activatedRoute.queryParamMap.subscribe({
         next: (params) => {
            this.searchForm?.controls['query'].patchValue(params.get('q'))
            this.search()
         },
      })
   }

   search() {
      let sectionIDs: number[] = []

      this.searchForm!.controls['sectionIDs'].getRawValue().forEach(
         (checked: boolean, index: number) => {
            if (checked) {
               sectionIDs.push(this.accessibleSections![index].sectionID)
            }
         }
      )

      this.searchService
         .search(this.searchForm!.controls['query'].getRawValue()!, sectionIDs)
         .subscribe({
            next: (res) => (this.searchResults = res),
         })
   }
}
