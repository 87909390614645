import {
   Component,
   inject,
   Input,
   OnChanges,
   OnInit,
   SimpleChanges,
} from '@angular/core'
import { CaseListComponent } from '../../../views/case-list/case-list.component'
import { ConferenceService } from '../../../services/conference.service'
import { MonarchCase } from '../../../shared/interfaces'
import { NoDataFoundComponent } from '../../../shared/components/empty-state/no-data-found/no-data-found.component'

@Component({
   selector: 'app-home-tab-next-conference',
   standalone: true,
   imports: [CaseListComponent, NoDataFoundComponent],
   templateUrl: './home-tab-next-conference.component.html',
})
export class HomeTabNextConferenceComponent implements OnInit, OnChanges {
   conferenceService = inject(ConferenceService)
   @Input({ required: true }) sectionID: number = 0
   casesNextConference: MonarchCase[] = []

   ngOnInit() {
      this.getCasesScheduledForNextConference()
   }
   ngOnChanges(changes: SimpleChanges) {
      this.getCasesScheduledForNextConference()
   }

   getCasesScheduledForNextConference() {
      this.conferenceService
         .getConferencesForSection(this.sectionID, true)
         .subscribe({
            next: (value) => {
               if (value === null) {
                  // API will return "null" which will result in a type error if this isn't caught. Sloppy but it works for now
                  this.casesNextConference = []
               }
               this.casesNextConference = value[0].caseList
            },
            error: (err) => {
               this.casesNextConference = []
            },
         })
   }
}
