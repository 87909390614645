import { Component, Input } from '@angular/core'
import { OnInit } from '@angular/core'
import { NgClass } from '@angular/common'
import { RouterLink } from '@angular/router'

@Component({
   selector: 'app-button',
   templateUrl: './button.component.html',
   standalone: true,
   imports: [RouterLink, NgClass],
})
export class ButtonComponent implements OnInit {
   @Input({ required: true }) label: string = ''
   @Input() providedRoute: string | undefined | null
   @Input() buttonType: string = 'primary'
   styles: Record<string, boolean> = {}

   ngOnInit() {
      this.setCurrentStyles()
   }

   setCurrentStyles() {
      switch (this.buttonType) {
         case 'primary':
            this.styles = {
               'bg-indigo-600': true,
               'text-white': true,
               'hover:bg-indigo-500': true,
            }
            break
         case 'secondary':
            this.styles = {
               'bg-white': true,
               'text-gray-900': true,
               'hover:bg-gray-50': true,
               'ring-1': true,
               'ring-insert': true,
               'ring-gray-300': true,
               'focus-visible:outline-gray-300': true,
            }
            break
      }
   }
}
