<h3 class="text-2xl font-bold leading-7 text-gray-900 mr-4 py-1.5">
   Administration
</h3>

<app-tab-selector
   [Tabs]="settingsTabs"
   [ActiveTab]="$activeTab"
></app-tab-selector>

@switch ($activeTab()) {
   @case (adminNavigationTabList.SECTIONS) {
      <app-admin-tab-sections></app-admin-tab-sections>
   }
   @case (adminNavigationTabList.USERS) {
      <app-admin-tab-users></app-admin-tab-users>
   }
   @case (adminNavigationTabList.ROLES) {
      <app-admin-tab-roles></app-admin-tab-roles>
   }
   @case (adminNavigationTabList.SECURITY) {
      <app-admin-tab-security></app-admin-tab-security>
   }
   @case (adminNavigationTabList.DROPDOWNS) {
      <app-admin-tab-dropdowns></app-admin-tab-dropdowns>
   }
   @case (adminNavigationTabList.COMPLICATIONS) {
      <app-admin-tab-complications></app-admin-tab-complications>
   }
   @case (adminNavigationTabList.OUTCOMES) {
      <app-admin-tab-outcomes></app-admin-tab-outcomes>
   }
   @case (adminNavigationTabList.FAILURE_MODE_TAXONOMY) {
      <app-admin-tab-failure-mode-taxonomy></app-admin-tab-failure-mode-taxonomy>
   }
   @case (adminNavigationTabList.LICENSE) {
      <app-admin-tab-subscription></app-admin-tab-subscription>
   }
}
