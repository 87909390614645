<form [formGroup]="pageForm">
   <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
   >
      New Case
   </h2>
   <h3 class="pt-4 text-lg font-semibold leading-6 text-gray-800">
      Identify the Patient
   </h3>

   <form [formGroup]="pageForm.controls.patientSearchCriteria">
      <p class="max-w-4xl text-sm text-gray-500">
         First, find the patient by entering demographic information. More
         information will yield a more specific search result.
      </p>

      <!--Inputs-->
      <div class="flex flex-row space-x-2">
         <app-input-text
            label="MRN"
            name="mrn"
            formControlName="MRN"
         ></app-input-text>
         <app-input-text label="First Name" name="firstName"></app-input-text>
         <app-input-text label="Last Name" name="lastName"></app-input-text>
         <app-input-text label="DOB" name="dob"></app-input-text>

         <div class="relative flex flex-col-reverse items-end">
            <app-button
               label="Search"
               (click)="searchForPatient()"
            ></app-button>
         </div>
      </div>
   </form>

   <!--Patient Search Results-->
   @if (patientSearchResults.length) {
      <div class="w-full lg:w-1/2 mt-2">
         <p class="py-2 max-w-4xl text-sm text-gray-500">
            Confirm the correct patient was identified by selecting them from
            this list
         </p>
         <app-interactive-table
            [columnConfiguration]="patientSearchResultColumns"
            [tableData]="patientSearchResults"
            [enableSelection]="true"
            (selectionEvent)="
               this.pageForm.controls.currentSelections.controls.patientFhirID.setValue(
                  $event.FHIRID
               )
            "
         ></app-interactive-table>
      </div>
   } @else {
      @if (patientsNotFound) {
         <div class="mt-2">
            <app-banner [title]="'No results found'"></app-banner>
         </div>
      }
   }

   <!--Encounter Search Results. Show if a patient is selected-->
   @if (
      this.pageForm.controls.currentSelections.controls.patientFhirID.getRawValue() !==
      ''
   ) {
      <h3 class="pt-4 text-lg font-semibold leading-6 text-gray-800">
         Identify the Primary Encounter
      </h3>
      <p class="max-w-4xl text-sm text-gray-500">
         Next, select the Encounter that should be linked with this Case. You
         can add more Encounters later.
      </p>

      <!--<div class="flex flex-col">-->
      <!--   <form>-->
      <!--      <div class="flex flex-row space-x-3">-->
      <!--         <div>-->
      <!--            <app-input-number-->
      <!--               label="Encounter #"-->
      <!--               name="CSN"-->
      <!--            ></app-input-number>-->
      <!--         </div>-->
      <!--      </div>-->
      <!--   </form>-->
      <!--</div>-->

      <app-interactive-table
         [tableData]="encounterSearchResults"
         [columnConfiguration]="encounterSearchResultColumns"
         [enableSelection]="true"
         (selectionEvent)="
            this.pageForm.controls.currentSelections.controls.encounterFhirID.setValue(
               $event.FHIRID
            )
         "
      ></app-interactive-table>
   } @else {
      @if (encountersNotFound) {
         <div class="mt-2">
            <app-banner [title]="'No results found'"></app-banner>
         </div>
      }
   }

   <!--Procedure Search Results-->
   @if (
      this.pageForm.controls.currentSelections.controls.encounterFhirID.getRawValue() !==
      ''
   ) {
      <div class="pt-4 pb-2">
         <h3 class="text-lg font-semibold leading-6 text-gray-800">
            Select Primary Procedure (optional)
         </h3>
         <p class="mt-2 max-w-4xl text-sm text-gray-500">
            If this Case should be linked with a specific surgical Case, select
            the appropriate Case from the list. You can add more later. Only
            procedures that occurred between the Start and End dates of the
            selected encounter will be displayed.
         </p>
      </div>

      @if (this.procedureSearchResults.length) {
         <app-interactive-table
            [columnConfiguration]="procedureSearchResultColumns"
            [tableData]="procedureSearchResults"
            [enableSelection]="true"
         ></app-interactive-table>
      } @else {
         @if (proceduresNotFound) {
            <div class="mt-2">
               <app-banner [title]="'No results found'"></app-banner>
            </div>
         }
      }
   }

   <div>
      <form [formGroup]="pageForm.controls.currentSelections">
         <div
            class="fixed bottom-0 right-0 w-full py-4 pr-4 backdrop-blur bg-white bg-opacity-90 border-t border-t-gray-300"
         >
            <div class="flex flex-row float-right">
               <p class="leading-7 text-gray-900 mr-4 flex items-center">
                  In which Section should this Case be created?
               </p>
               <select
                  id="clinicalSection"
                  name="clinicalSection"
                  formControlName="sectionID"
                  class="block rounded-md min-w-48 border-0 py-1.5 pl-3 pr-10 mr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
               >
                  @for (option of userAccessibleSections; track $index) {
                     <option [ngValue]="option.sectionID">
                        {{ option.name }}
                     </option>
                  }
               </select>
               <!--TODO: Disable when no patient selected-->
               <app-button
                  label="Create Case"
                  buttonType="primary"
                  (click)="createAndNavigateToCaseDetail()"
               ></app-button>
            </div>
         </div>
      </form>
   </div>
</form>
