import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseLinkedProcedure, MonarchCaseTab } from '../../../shared/interfaces'
import { CaseManagementService } from '../../../services/case-management.service'
import {
   BadgeTheme,
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { OutcomeFormComponent } from '../tab-outcomes/outcome-form.component'
import { LinkedProcedureFormComponent } from './linked-procedure-form.component'
import { ModalComponent } from '../../../shared/components/modal/modal.component'

@Component({
   selector: 'app-tab-procedures',
   standalone: true,
   imports: [
      CommonModule,
      SimpleListComponent,
      OutcomeFormComponent,
      LinkedProcedureFormComponent,
      ModalComponent,
   ],
   templateUrl: './tab-procedures.component.html',
})
export class TabProceduresComponent implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) patientFhirID!: string
   @Input({ required: true }) allowEditing: boolean = false
   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   $caseLinkedProcedures: WritableSignal<CaseLinkedProcedure[]> = signal([])
   linkedProcedureDisplayList: SimpleList = []
   selectedProcedureID: number = 0

   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.linkedProcedureDisplayList = this.$caseLinkedProcedures().map(
            (x: CaseLinkedProcedure) => mapLinkedProcedureToListItem(x)
         )
      })
   }
   ngOnInit() {
      this.caseManagementService.getRelatedProcedures(this.caseID).subscribe({
         next: (value) => {
            if (value !== null) {
               // TODO: API should return an empty array or 204 instead
               this.$caseLinkedProcedures.set(value)
            }
         },
      })
   }
}

function mapLinkedProcedureToListItem(e: CaseLinkedProcedure): SimpleListItem {
   let item: SimpleListItem = {
      id: e.internalID,
      title: e.procedureName,
      description: e.note,
      propertyPairs: [],
      badges: [],
   }
   if (e.isPrimary) {
      let primaryBadge = {
         title: 'Primary Procedure',
         theme: BadgeTheme.GREEN,
      }
      item.badges?.push(primaryBadge)
   }

   if (e.cptCode) {
      item.propertyPairs?.push({
         key: 'CPT',
         display: 'CPT',
         value: e.cptCode,
      })
   }
   if (e.performedAt) {
      item.propertyPairs?.push({
         key: 'PERFORMED',
         display: 'Performed At',
         value: e.performedAt.toLocaleString(),
      })
   }

   return item
}
