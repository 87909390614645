import { Component, Input } from '@angular/core'
import { NgClass } from '@angular/common'

@Component({
   selector: 'app-label',
   standalone: true,
   imports: [NgClass],
   templateUrl: './label.component.html',
})
export class LabelComponent {
   @Input() color: string = 'gray'
   @Input() text: string = 'label'
}
