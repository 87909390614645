import {
   Component,
   inject,
   Input,
   OnChanges,
   OnInit,
   SimpleChanges,
   WritableSignal,
} from '@angular/core'
import { CaseListComponent } from '../../../views/case-list/case-list.component'
import { CaseManagementService } from '../../../services/case-management.service'
import { MonarchCase } from '../../../shared/interfaces'
import { NoDataFoundComponent } from '../../../shared/components/empty-state/no-data-found/no-data-found.component'

@Component({
   selector: 'app-home-tab-open-cases',
   standalone: true,
   imports: [CaseListComponent, NoDataFoundComponent],
   templateUrl: './home-tab-open-cases.component.html',
})
export class HomeTabOpenCasesComponent implements OnInit, OnChanges {
   @Input({ required: true }) sectionID: number = 0
   caseManagementService = inject(CaseManagementService)
   casesInProgress: MonarchCase[] = []
   ngOnInit() {
      this.refreshCasesForSelectedSection()
   }
   ngOnChanges(changes: SimpleChanges) {
      this.refreshCasesForSelectedSection()
   }

   refreshCasesForSelectedSection() {
      this.caseManagementService
         .getCasesForSections([this.sectionID])
         .subscribe({
            next: (value) => {
               // TODO: This type of filtering should be handled in the API
               this.casesInProgress = value.slice().filter((x) => x.isOpen)
            },
            error: (err) => console.log(err),
            complete: () => {
               console.log(this.casesInProgress)
            },
         })
   }
}
