<div
   class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
   [ngClass]="getNotificationClasses(this.type)"
>
   <div class="p-4">
      <div class="flex items-start">
         <div class="flex-shrink-0">
            <svg
               class="h-6 w-6"
               [ngClass]="getNotificationClasses(this.type)"
               fill="none"
               viewBox="0 0 24 24"
               stroke-width="1.5"
               stroke="currentColor"
               aria-hidden="true"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  [attr.d]="getSVGPath(this.type)"
               />
            </svg>
         </div>
         <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium">{{ title }}</p>
            <p class="mt-1 text-sm text-gray-500">{{ body }}</p>
         </div>
      </div>
   </div>
</div>
