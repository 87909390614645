<div class="flow-root">
   <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
         <table class="min-w-full divide-y divide-gray-300">
            <thead>
               <tr>
                  <th
                     scope="col"
                     class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                     Patient
                  </th>
                  <th
                     scope="col"
                     class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                     Primary Encounter
                  </th>
                  <th
                     scope="col"
                     class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                     Diagnosis
                  </th>
                  <th
                     scope="col"
                     class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                     Case Status
                  </th>
                  <th
                     scope="col"
                     class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                     Case ID
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                     <span class="sr-only">View</span>
                  </th>
               </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
               <!--Table row-->
               <tr *ngFor="let c of caseList">
                  <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                     <div class="flex items-center">
                        <div>
                           <div class="font-medium text-gray-900">
                              {{ c.patientName }}
                           </div>
                           <div class="mt-1 text-gray-500">
                              MRN
                              <span class="font-medium">{{ c.patientMRN }}</span
                              >, DOB:
                              <span class="font-medium">{{
                                 c.patientDOB | date
                              }}</span>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                     <div class="text-gray-900">
                        {{ c.encounterStart | date }} -
                        {{ c.encounterEnd | date }}
                     </div>
                     <div class="mt-1 text-gray-500">
                        Encounter
                        <span class="font-medium">{{ c.encounterCSN }}</span>
                     </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-900">
                     {{ c.diagnosis }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                     <span
                        class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                        >{{ c.isOpen | booleanPipe }}</span
                     >
                  </td>
                  <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                     {{ c.caseID }}
                  </td>
                  <td
                     class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                     <a
                        class="text-indigo-600 hover:text-indigo-900 hover:underline"
                        routerLink="/case/{{ c.caseID }}"
                        >View<span class="sr-only">, Lindsay Walton</span></a
                     >
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
