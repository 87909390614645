<header class="flex bg-white justify-between">
   <div class="pl-8"></div>
   <div class="flex flex-row-reverse h-16 w-1/2">
      <div class="z-10 ml-4 flex items-center pr-8 w-full">
         <!--Search-->
         <div class="flex flex-1 items-center px-2">
            <div class="w-full">
               <label for="search" class="sr-only">Search</label>
               <div class="relative">
                  <div
                     class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                     <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                     >
                        <path
                           fill-rule="evenodd"
                           d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                           clip-rule="evenodd"
                        />
                     </svg>
                  </div>
                  <input
                     id="search"
                     #searchInput
                     autocomplete="off"
                     name="search"
                     class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                     placeholder="Search"
                     type="search"
                  />
                  <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                     <kbd
                        class="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-xs text-gray-400"
                        >/</kbd
                     >
                  </div>
               </div>
            </div>
         </div>
         <!--Notifications-->
         <!--<button-->
         <!--   type="button"-->
         <!--   class="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"-->
         <!--&gt;-->
         <!--   <span class="absolute -inset-1.5"></span>-->
         <!--   <span class="sr-only">View notifications</span>-->
         <!--   <svg-->
         <!--      class="h-6 w-6"-->
         <!--      fill="none"-->
         <!--      viewBox="0 0 24 24"-->
         <!--      stroke-width="1.5"-->
         <!--      stroke="currentColor"-->
         <!--      aria-hidden="true"-->
         <!--   >-->
         <!--      <path-->
         <!--         stroke-linecap="round"-->
         <!--         stroke-linejoin="round"-->
         <!--         d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"-->
         <!--      />-->
         <!--   </svg>-->
         <!--</button>-->

         <!-- Profile -->
         <div class="relative ml-4 flex-shrink-0">
            <button
               type="button"
               class="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
               id="user-menu-button"
               aria-expanded="false"
               aria-haspopup="true"
               (click)="userMenuIsDisplayed = true"
            >
               <span class="absolute -inset-1.5"></span>
               <span class="sr-only">Open user menu</span>
               <!--TODO: Profile pic from user's profile-->
               <img
                  class="h-10 w-10 rounded-full"
                  src="{{ profilePath }}"
                  onerror="this.src='assets/profile/default_profile.png'"
                  alt="profile pic"
               />
               <!--src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"-->
            </button>
            @if (userMenuIsDisplayed) {
               <!--FIXME: This is hard-coded to only logout a user, rather that responding to which option was selected-->
               <app-floating-options-menu
                  [options]="userMenuOptions"
                  [isMultiSelect]="false"
                  [firstRowIsHeader]="true"
                  (click)="this.userService.logout()"
               ></app-floating-options-menu>
            }
         </div>
      </div>
   </div>
</header>
