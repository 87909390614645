import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { CaseLinkedPractitioner } from '../../../shared/interfaces'
import { LinkedStaffFormComponent } from './linked-staff-form.component'
import { AdverseEventFormComponent } from '../tab-adverse-events/adverse-event-form.component'
import { CaseManagementService } from '../../../services/case-management.service'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'
import {
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { LinkedEncounterFormComponent } from '../tab-encounters/linked-encounter-form.component'
import { ActionItemFormComponent } from '../tab-action-items/action-item-form.component'

@Component({
   selector: 'app-tab-staff',
   standalone: true,
   imports: [
      ActionTabComponent,
      ButtonComponent,
      InteractiveTableComponent,
      LinkedStaffFormComponent,
      AdverseEventFormComponent,
      SimpleListComponent,
      LinkedEncounterFormComponent,
      ActionItemFormComponent,
   ],
   templateUrl: './tab-staff.component.html',
})
export class TabStaffComponent implements OnInit {
   @Input({ required: true }) caseID!: number
   $formIsDisplayed: WritableSignal<boolean>
   $isProcessing: WritableSignal<boolean>
   $caseLinkedPractitioners: WritableSignal<CaseLinkedPractitioner[]>
   caseLinkedPractitionerDisplayList: SimpleListItem[] = []

   constructor(
      private caseService: CaseManagementService,
      private notificationService: NotificationService
   ) {
      this.$isProcessing = signal(false)
      this.$formIsDisplayed = signal(false)
      this.$caseLinkedPractitioners = signal([])
      effect(() => {
         this.caseLinkedPractitionerDisplayList =
            this.$caseLinkedPractitioners().map((x) =>
               this.mapPractitionerToListItem(x)
            )
      })
   }

   ngOnInit() {
      this.$isProcessing.set(true)
      this.caseService.getRelatedPractitioners(this.caseID).subscribe({
         next: (value) => {
            console.log('Practitioners is', value)
            this.$caseLinkedPractitioners.set(value.slice(0))
         },
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'There was an issue',
               err
            ),
         complete: () => this.$isProcessing.set(false),
      })
   }

   mapPractitionerToListItem(x: CaseLinkedPractitioner): SimpleListItem {
      return {
         id: x.internalID,
         title: x.fullName,
         description: x.note,
         propertyPairs: [],
      }
   }
}
