import {
   Component,
   ElementRef,
   HostListener,
   OnInit,
   ViewChild,
} from '@angular/core'
import { CaseManagementService } from './services/case-management.service'
import {
   NotificationService,
   SimpleNotification,
} from './services/notification.service'
import { LoggingService } from './services/logging.service'
import {
   NavigationEnd,
   Router,
   RouterLink,
   RouterOutlet,
} from '@angular/router'
import { LoginComponent } from './pages/login/login.component'
import { NotificationComponent } from './components/notification/notification.component'
import { CommonModule } from '@angular/common'
import { UserService } from './services/user.service'
import { NavMenuComponent } from './components/nav-menu/nav-menu.component'
import { NavBarComponent } from './components/nav-bar/nav-bar.component'
import { UserActivationComponent } from './pages/user-activation/user-activation.component'

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   standalone: true,
   providers: [CaseManagementService],
   imports: [
      NotificationComponent,
      RouterOutlet,
      RouterLink,
      LoginComponent,
      CommonModule,
      NavMenuComponent,
      NavBarComponent,
      UserActivationComponent,
   ],
})
export class AppComponent implements OnInit {
   constructor(
      private notificationListener: NotificationService,
      private logger: LoggingService,
      public userService: UserService,
      private router: Router
   ) {}

   // MARK: Properties
   title = 'Monarch'
   @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>

   isRequestingAccountActivation: boolean = false

   // MARK: Lifecycle
   ngOnInit() {
      this.listenForNotificationEvents()

      // TODO: This is probably a terrible way to check if a particular route is matching, but it's working trying to refactor the existing
      // single router into a dual-router-outlet is proving much more difficult than expected
      this.router.events.subscribe({
         next: (value) => {
            this.isRequestingAccountActivation =
               value instanceof NavigationEnd &&
               value.url.startsWith('/activateAccount')
         },
      })

      this.logger.info('Monarch initialized')
   }

   // MARK: Logic f(x)
   // listenForNotificationEvents waits for any user notification events
   // When an event is received, it will be displayed on the user's screen
   listenForNotificationEvents() {
      this.notificationListener.notification$.subscribe({
         next: (value: SimpleNotification) => {
            this.logger.debug('noficiationListener received a new notification')
            this.notificationIsDisplayed = true
            this.displayedNotification = value
            setTimeout(() => (this.notificationIsDisplayed = false), 3000)
         },
      })
      this.logger.debug('Listening for notification events')
   }

   displayedNotification: SimpleNotification = <SimpleNotification>{} //TODO: Should be in notification service

   notificationIsDisplayed: boolean = false // Same sa above
}
