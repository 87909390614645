import { inject, Injectable } from '@angular/core'
import { SearchResult } from '../shared/interfaces'
import { Observable } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
export class SearchService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}

   search(
      query: string,
      sectionIDs: number[] | null
   ): Observable<SearchResult[]> {
      let params = new HttpParams()
      params = params.append('q', query)
      if (sectionIDs !== null) {
         for (const id of sectionIDs) {
            params = params.append('sectionID', id)
         }
      }

      return this.http.get<SearchResult[]>(`${this.endpoint}/search`, {
         params: params,
      })
   }
}
