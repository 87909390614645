import {
   Component,
   Input,
   OnInit,
   Output,
   signal,
   Signal,
   WritableSignal,
} from '@angular/core'
import { NgClass } from '@angular/common'

@Component({
   selector: 'app-tab-selector',
   standalone: true,
   imports: [NgClass],
   templateUrl: './tab-selector.component.html',
})
export class TabSelectorComponent implements OnInit {
   @Input({ required: true }) Tabs!: string[]
   @Input({ required: true }) ActiveTab!: WritableSignal<string>
   ngOnInit() {
      console.log('TabSelectorComponent initialized')
   }
}
