import {
   Component,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   signal,
   WritableSignal,
} from '@angular/core'
import { InteractiveTableColumn } from '../../interfaces'
import { BooleanPipePipe } from '../../../pipes/boolean-pipe.pipe'
import { DatePipe, NgClass } from '@angular/common'
import { MatProgressBar } from '@angular/material/progress-bar'
import { LabelComponent } from '../badges/label/label.component'

@Component({
   selector: 'app-interactive-table',
   standalone: true,
   imports: [
      BooleanPipePipe,
      DatePipe,
      MatProgressBar,
      NgClass,
      LabelComponent,
   ],
   templateUrl: './interactive-table.component.html',
})

// InteractiveTableComponent represents a table with dynamic columns and is customizable
// with sorting and filtering
export class InteractiveTableComponent implements OnInit {
   @Input({ required: true }) columnConfiguration: InteractiveTableColumn[] = []
   @Input({ required: true }) tableData: any[] = []

   // When enableSelection is true, an additional column will be added with a checkbox
   @Input() enableSelection: boolean = false

   // When isProcessing is true, an indeterminate progress bar will be shown above the column headers
   // When a sort, filter, and rearrange operations are performed, the table will write to the signal to notify
   // the parent component that an operation is pending.
   // By default, no progress bar is shown, and it's up to the invoker to start it
   @Input() isProcessing: WritableSignal<boolean> = signal(false)

   @Output() selectionEvent = new EventEmitter<any>()

   selectedRowIndex: number | undefined

   ngOnInit() {
      if (
         // Prevent adding a selectionColumn multiple times to the referenced array
         this.enableSelection &&
         !this.columnConfiguration.some((x) => x.key === 'selectionColumn')
      ) {
         this.columnConfiguration.push({
            key: 'selectionColumn',
            dataType: 'string',
            displayValue: '',
            sortIndex: this.columnConfiguration.length + 1,
            isVisible: true,
         })
      }
   }

   selectRow(row: any, index: number) {
      this.selectionEvent.emit(row)
      this.selectedRowIndex = index
   }
}
