import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { AdminService } from '../../../services/admin.service'
import {
   ClinicalSection,
   InteractiveTableColumn,
} from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { SectionFormComponent } from '../../../forms/section-form/section-form.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'

@Component({
   selector: 'app-admin-tab-sections',
   standalone: true,
   imports: [
      ButtonComponent,
      InteractiveTableComponent,
      SectionFormComponent,
      ActionTabComponent,
   ],
   templateUrl: './admin-tab-sections.component.html',
})
export class AdminTabSectionsComponent implements OnInit {
   adminService = inject(AdminService)
   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   sectionList: ClinicalSection[] = []
   $isProcessing: WritableSignal<boolean> = signal(false)
   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getAllSections().subscribe({
         next: (value) => (this.sectionList = value.slice(0)),
         complete: () => this.$isProcessing.set(false),
      })
   }
   columns: InteractiveTableColumn[] = [
      {
         key: 'sectionID',
         displayValue: 'ID',
         isVisible: true,
         sortIndex: 0,
         dataType: 'number',
         cellAlignment: 'center',
      },
      {
         key: 'name',
         displayValue: 'Name',
         isVisible: true,
         sortIndex: 1,
         dataType: 'string',
         headerAlignment: 'left',
      },
      {
         key: 'enabledRoles',
         displayValue: 'Enabled Roles',
         isVisible: true,
         sortIndex: 2,
         dataType: 'list',
         listPath: 'roleName',
         headerAlignment: 'left',
      },
   ]
}
