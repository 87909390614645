import {
   Component,
   OnInit,
   inject,
   WritableSignal,
   signal,
} from '@angular/core'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { AdminService } from '../../../services/admin.service'
import {
   InstanceRoleConfiguration,
   InteractiveTableColumn,
   Role,
} from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'

@Component({
   selector: 'app-admin-tab-roles',
   standalone: true,
   imports: [ButtonComponent, InteractiveTableComponent, ActionTabComponent],
   templateUrl: './admin-tab-roles.component.html',
})
export class AdminTabRolesComponent implements OnInit {
   adminService = inject(AdminService)
   roles: InstanceRoleConfiguration | undefined
   $isProcessing: WritableSignal<boolean> = signal(false)
   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getInstanceRoles().subscribe({
         next: (value) => (this.roles = value),
         complete: () => this.$isProcessing.set(false),
      })
   }

   columns: InteractiveTableColumn[] = [
      {
         key: 'roleID',
         dataType: 'number',
         sortIndex: 0,
         isVisible: true,
         displayValue: 'Role ID',
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'roleName',
         dataType: 'string',
         sortIndex: 1,
         isVisible: true,
         displayValue: 'Name',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'securityPoints',
         dataType: 'list',
         sortIndex: 2,
         isVisible: true,
         displayValue: 'Permissions',
         listPath: 'securityPointName',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
   ]
}
