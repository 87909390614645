import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import {
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { FailureModeFormComponent } from './failure-mode-form.component'
import { CaseFailure, MonarchCaseTab } from '../../../shared/interfaces'

@Component({
   selector: 'app-tab-failures-component',
   standalone: true,
   imports: [CommonModule, FailureModeFormComponent, SimpleListComponent],
   templateUrl: './tab-case-failure-modes.component.html',
})
export class TabCaseFailureModes implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) allowEditing: boolean = false
   $caseFailures: WritableSignal<CaseFailure[]> = signal([])
   $failureModeFormIsDisplayed: WritableSignal<boolean> = signal(false)
   caseFailuresDisplayList: SimpleList = []
   selectedCaseFailureID: number = 0

   mapFailureModeToListItem(fm: CaseFailure): SimpleListItem {
      let createDate = new Date(fm.createdAt)

      return {
         id: fm.id,
         title: fm.failureModeName,
         description: fm.note,
         propertyPairs: [
            {
               key: 'CREATEDATE',
               display: 'Created',
               value: createDate.toLocaleString(),
            },
            {
               key: 'CREATEDBY',
               display: 'By',
               value: fm.createdBy,
            },
            {
               key: 'PATH',
               display: 'Taxonomy Path',
               value: fm.failureModePath,
            },
         ],
      }
   }
   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.caseFailuresDisplayList = this.$caseFailures().map(
            (x: CaseFailure) => this.mapFailureModeToListItem(x)
         )
      })
   }

   ngOnInit() {
      this.caseManagementService.getRelatedFailures(this.caseID).subscribe({
         next: (value) =>
            this.$caseFailures.set(value.filter((x) => !x.isDeleted)),
      })
   }
}
