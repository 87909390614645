<div class="flex space-x-8">
   <div class="w-full">
      <!--Section Header-->
      <div class="flex justify-between py-2 border-b border-gray-100">
         <!--Title-->
         <div class="flex items-center">
            <p
               class="text-base font-semibold leading-6 text-gray-900 align-middle"
            >
               Linked Providers
            </p>
         </div>

         <app-button
            [label]="'Add Linked Provider'"
            (click)="$formIsDisplayed.set(true)"
         ></app-button>
      </div>

      <!--Content-->
      <div class="flex">
         <app-simple-list [listData]="caseLinkedPractitionerDisplayList">
         </app-simple-list>

         <!--Right Side-->

         @if ($formIsDisplayed()) {
            <app-linked-staff-form
               [caseID]="caseID"
               [$caseLinkedStaff]="$caseLinkedPractitioners"
               [$formIsDisplayed]="$formIsDisplayed"
               class="w-2/3"
            >
            </app-linked-staff-form>
         }
      </div>
   </div>
</div>
