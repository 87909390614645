import { Component, Input } from '@angular/core'

@Component({
   selector: 'app-banner',
   standalone: true,
   imports: [],
   templateUrl: './banner.component.html',
})
export class BannerComponent {
   @Input({ required: true }) title: string = ''
}
