import { Component, Input } from '@angular/core'
import { MonarchCase } from '../../shared/interfaces'
import { RouterLink } from '@angular/router'
import { NgFor, DatePipe } from '@angular/common'
import { BooleanPipePipe } from '../../pipes/boolean-pipe.pipe'

@Component({
   selector: 'app-case-list',
   templateUrl: './case-list.component.html',
   standalone: true,
   imports: [NgFor, RouterLink, DatePipe, BooleanPipePipe],
})
export class CaseListComponent {
   @Input() caseList: MonarchCase[] = []
}
