<app-modal [title]="'New Section'" [$isDisplayed]="$formIsDisplayed">
   <form [formGroup]="sectionForm">
      <div class="flex flex-col">
         <app-input-text
            [name]="'sectionName'"
            [label]="'Name'"
            formControlName="sectionName"
         ></app-input-text>
         <!--<p>Enabled Roles</p>-->
         <!--<div><input type="checkbox" /><span>Administrator</span></div>-->
         <!--<div><input type="checkbox" /><span>Super User</span></div>-->
         <!--<div><input type="checkbox" /><span>Standard User</span></div>-->
         <!--<div><input type="checkbox" /><span>Read-Only</span></div>-->
      </div>
   </form>
   <div class="ngc-footer-right">
      <app-button label="Create" (click)="create()"></app-button>
   </div>
</app-modal>
