<ng-container *ngIf="dataIsLoaded">
   <!--Background Opacity-->
   <div
      class="relative z-30"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
   >
      <div
         class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
         (click)="cancel()"
      ></div>

      <!--Modal-->
      <div
         class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all"
      >
         <div class="border-b border-gray-200 pb-1.5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">
               New Adverse Event
            </h3>
         </div>

         <form [formGroup]="adverseEventForm">
            <div class="mt-2">
               <label
                  for="category"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Category</label
               >
               <select
                  id="category"
                  formControlName="complicationCategoryID"
                  name="category"
                  (change)="setSelectedCategory($event)"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
               >
                  <option [ngValue]="null"></option>
                  <option
                     *ngFor="let option of adverseEventCategory"
                     [ngValue]="option.id"
                  >
                     {{ option.name }}
                  </option>
               </select>
            </div>

            <div class="mt-2">
               <label
                  for="adverseEvent"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Adverse Event</label
               >
               <select
                  formControlName="complicationID"
                  id="adverseEvent"
                  name="adverseEvent"
                  class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
               >
                  <option [ngValue]="null"></option>
                  <option
                     *ngFor="let option of selectableAdverseEventOptions"
                     [ngValue]="option.id"
                  >
                     {{ option.name }}
                  </option>
               </select>
            </div>

            <div class="mt-2">
               <label
                  for="note"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Comment</label
               >
               <div class="mt-1">
                  <input
                     formControlName="note"
                     type="text"
                     name="note"
                     id="note"
                     autocomplete="off"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
               </div>
            </div>

            <!--Footer-->
            <div class="mt-2">
               <div class="flex justify-between">
                  <div class="flex flex-row space-x-1">
                     <button
                        type="button"
                        (click)="cancel()"
                        class="float-right mt-2 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:bg-gray-100 hover:duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                     >
                        Cancel
                     </button>
                     @if (!isNewRecord(this.selectedAdverseEventID)) {
                        <app-button-danger
                           [label]="'Delete'"
                           (click)="delete()"
                        ></app-button-danger>
                     }
                  </div>
                  <div>
                     <button
                        type="button"
                        (click)="submit()"
                        class="float-right mt-2 inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                     >
                        Save
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   </div>
</ng-container>
