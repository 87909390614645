import { Component, inject, Input, signal, WritableSignal } from '@angular/core'
import { ModalComponent } from '../../shared/components/modal/modal.component'
import { InputTextComponent } from '../../shared/components/controls/input-text/input-text.component'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { AdminService } from '../../services/admin.service'
import { ButtonComponent } from '../../shared/components/button/button.component'
import {
   NotificationService,
   NotificationType,
} from '../../services/notification.service'

@Component({
   selector: 'app-section-form',
   standalone: true,
   imports: [
      ModalComponent,
      InputTextComponent,
      ButtonComponent,
      ReactiveFormsModule,
   ],
   templateUrl: './section-form.component.html',
})
export class SectionFormComponent {
   @Input({ required: true }) $formIsDisplayed: WritableSignal<boolean> =
      signal(false)
   adminService = inject(AdminService)
   notificationService = inject(NotificationService)

   sectionForm = new FormGroup({
      sectionName: new FormControl('', { validators: [], nonNullable: true }),
   })

   create() {
      this.adminService
         .createSection(this.sectionForm.getRawValue())
         .subscribe({
            next: (value) => console.log('Created'),
            error: (err) =>
               this.notificationService.new(NotificationType.Error, err),
            complete: () => this.$formIsDisplayed.set(false),
         })
   }
}
