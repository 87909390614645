import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { InteractiveTableColumn } from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'

@Component({
   selector: 'app-admin-tab-failure-mode-taxonomy',
   standalone: true,
   imports: [InteractiveTableComponent, ButtonComponent, ActionTabComponent],
   templateUrl: './admin-tab-failure-mode-taxonomy.component.html',
})
export class AdminTabFailureModeTaxonomyComponent implements OnInit {
   adminService = inject(AdminService)
   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   $isProcessing: WritableSignal<boolean> = signal(false)

   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getFailureModeTaxonomy().subscribe({
         next: (value) => (this.taxonomyData = value),
         complete: () => this.$isProcessing.set(false),
      })
   }
   taxonomyColumns: InteractiveTableColumn[] = [
      {
         key: 'failureModeID',
         displayValue: 'Failure Mode ID',
         dataType: 'number',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'rootName',
         displayValue: 'Taxonomy Root',
         dataType: 'string',
         isVisible: true,
         sortIndex: 3,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'name',
         displayValue: 'Failure Mode Name',
         dataType: 'string',
         isVisible: true,
         sortIndex: 1,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'path',
         displayValue: 'Taxonomy Path',
         dataType: 'string',
         isVisible: true,
         sortIndex: 2,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
   ]

   taxonomyData: any[] = []
}
