@if (navigationExpanded) {
   <ng-container>
      <div
         class="hidden lg:fixed lg:inset-y-0 lg:z-20 lg:flex lg:w-72 lg:flex-col"
      >
         <div
            class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 divide-y"
         >
            <div
               class="flex h-16 shrink-0 items-center content-center justify-between cursor-pointer"
               routerLink="home"
            >
               <!--<img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company">-->
               <div class="flex flex-col">
                  <h2 class="text-2xl text-orange-400 font-semibold">
                     Monarch.
                  </h2>
               </div>
            </div>
            <nav class="flex flex-1 flex-col py-y">
               <div class="flex flex-col justify-between h-full">
                  <!--        Top Nav-->
                  <div>
                     <div>
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                           <li>
                              <ul role="list" class="-mx-2 space-y-1">
                                 @for (
                                    item of coreNavigationEntries;
                                    track item.path
                                 ) {
                                    <li>
                                       <a
                                          routerLink="{{ item.path }}"
                                          class="flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 group hover:bg-gray-50 hover:text-indigo-600"
                                       >
                                          <svg
                                             class="h-6 w-6 shrink-0 hover:text-indigo-600"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor"
                                             aria-hidden="true"
                                          >
                                             <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                [attr.d]="item.svgPath"
                                             />
                                          </svg>
                                          {{ item.name }}
                                       </a>
                                       <!--class="w-max rounded bg-gray-900 px-2 py-1 text-sm font-medium text-gray-50 opacity-0 shadow transition-opacity group-hover:opacity-100"></span>-->
                                       <!--</a>-->
                                    </li>
                                 }
                              </ul>
                           </li>
                        </ul>
                     </div>
                     <div>
                        <h3
                           class="text-gray-700 font-semibold text-lg pt-4 pb-2"
                        >
                           Reports
                        </h3>
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                           <li>
                              <ul role="list" class="-mx-2 space-y-1">
                                 @for (
                                    item of reportNavigationEntries;
                                    track item.path
                                 ) {
                                    <li>
                                       <a
                                          routerLink="{{ item.path }}"
                                          class="flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 group hover:bg-gray-50 hover:text-indigo-600"
                                       >
                                          <svg
                                             class="h-6 w-6 shrink-0 hover:text-indigo-600"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor"
                                             aria-hidden="true"
                                          >
                                             <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                [attr.d]="item.svgPath"
                                             />
                                          </svg>
                                          {{ item.name }}
                                       </a>
                                       <!--class="w-max rounded bg-gray-900 px-2 py-1 text-sm font-medium text-gray-50 opacity-0 shadow transition-opacity group-hover:opacity-100"></span>-->
                                       <!--</a>-->
                                    </li>
                                 }
                              </ul>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <!--        Bottom Nav-->
                  <div>
                     <ul>
                        <li class="mt-auto">
                           <a
                              routerLink="administration"
                              class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                           >
                              <svg
                                 class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 aria-hidden="true"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                 />
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                 />
                              </svg>
                              Administration
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </nav>
         </div>
      </div>
   </ng-container>
} @else {
   <ng-container id="navigationCollapsed">
      <div
         class="hidden lg:fixed lg:inset-y-0 lg:z-20 lg:flex lg:w-16 lg:flex-col"
      >
         <div
            class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 divide-y"
         >
            <div
               class="flex h-16 shrink-0 items-center content-center justify-between cursor-pointer"
               routerLink="home"
            >
               <div class="flex flex-col">
                  <h2 class="text-2xl text-orange-400 font-semibold">M.</h2>
               </div>
            </div>
            <nav class="flex flex-1 flex-col py-y">
               <div class="flex flex-col justify-between h-full">
                  <!--        Top Nav-->
                  <div>
                     <div>
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                           <li>
                              <ul role="list" class="-mx-2 space-y-1">
                                 @for (
                                    item of coreNavigationEntries;
                                    track item.path
                                 ) {
                                    <li>
                                       <a
                                          routerLink="{{ item.path }}"
                                          class="flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 group hover:bg-gray-50 hover:text-indigo-600"
                                       >
                                          <svg
                                             class="h-6 w-6 shrink-0 hover:text-indigo-600"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor"
                                             aria-hidden="true"
                                          >
                                             <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                [attr.d]="item.svgPath"
                                             />
                                          </svg>
                                       </a>
                                    </li>
                                 }
                              </ul>
                           </li>
                        </ul>
                     </div>
                     <div>
                        <h3
                           class="text-gray-700 font-semibold text-lg pt-4 pb-2"
                        >
                           <hr />
                        </h3>
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                           <li>
                              <ul role="list" class="-mx-2 space-y-1">
                                 @for (
                                    item of reportNavigationEntries;
                                    track item.path
                                 ) {
                                    <li>
                                       <a
                                          routerLink="{{ item.path }}"
                                          class="flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 group hover:bg-gray-50 hover:text-indigo-600"
                                       >
                                          <svg
                                             class="h-6 w-6 shrink-0 hover:text-indigo-600"
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor"
                                             aria-hidden="true"
                                          >
                                             <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                [attr.d]="item.svgPath"
                                             />
                                          </svg>
                                       </a>
                                    </li>
                                 }
                              </ul>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <!--        Bottom Nav-->
                  <div>
                     <ul>
                        <li class="mt-auto">
                           <a
                              routerLink="administration"
                              class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                           >
                              <svg
                                 class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 aria-hidden="true"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                 />
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                 />
                              </svg>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </nav>
         </div>
      </div>
   </ng-container>
}
