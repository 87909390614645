<app-action-tab [$formIsDisplayed]="$formIsDisplayed">
   <app-button
      [label]="'New Section'"
      (click)="$formIsDisplayed.set(true)"
      class="ngc-button-region"
   ></app-button>
   <app-interactive-table
      [columnConfiguration]="columns"
      [tableData]="sectionList"
      [isProcessing]="$isProcessing"
      class="ngc-table-region"
   ></app-interactive-table>
   <app-section-form
      [$formIsDisplayed]="$formIsDisplayed"
      class="ngc-form-region"
   ></app-section-form>
</app-action-tab>
