import { Component, signal, WritableSignal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserSecurityReportComponent } from '../../views/user-security-report/user-security-report.component'
import { ButtonComponent } from '../../shared/components/button/button.component'
import { UserFormComponent } from '../../forms/user-form/user-form.component'
import { TabSelectorComponent } from '../../components/tab-selector/tab-selector.component'
import { InteractiveReportComponent } from '../../components/interactive-report/interactive-report.component'
import { InteractiveTableComponent } from '../../shared/components/table/interactive-table.component'
import { AdminTabUsersComponent } from './admin-tab-users/admin-tab-users.component'
import { AdminTabRolesComponent } from './admin-tab-roles/admin-tab-roles.component'
import { AdminTabSectionsComponent } from './admin-tab-sections/admin-tab-sections.component'
import { AdminTabFailureModeTaxonomyComponent } from './admin-tab-failure-mode-taxonomy/admin-tab-failure-mode-taxonomy.component'
import { AdminTabSubscriptionComponent } from './admin-tab-subscription/admin-tab-subscription.component'
import { adminNavigationTabList } from '../../shared/enums'
import { AdminTabComplicationsComponent } from './admin-tab-complications/admin-tab-complications.component'
import { AdminTabOutcomesComponent } from './admin-tab-outcomes/admin-tab-outcomes.component'
import { AdminTabDropdownsComponent } from './admin-tab-dropdowns/admin-tab-dropdowns.component'
import { AdminTabSecurityComponent } from './admin-tab-security/admin-tab-security.component'

@Component({
   selector: 'app-administration-page',
   standalone: true,
   imports: [
      CommonModule,
      UserSecurityReportComponent,
      ButtonComponent,
      UserFormComponent,
      TabSelectorComponent,
      InteractiveReportComponent,
      InteractiveTableComponent,
      AdminTabUsersComponent,
      AdminTabRolesComponent,
      AdminTabSectionsComponent,
      AdminTabFailureModeTaxonomyComponent,
      AdminTabSubscriptionComponent,
      AdminTabComplicationsComponent,
      AdminTabOutcomesComponent,
      AdminTabDropdownsComponent,
      AdminTabSecurityComponent,
   ],
   templateUrl: './administration-page.component.html',
})
export class AdministrationPageComponent {
   settingsTabs = Object.values(adminNavigationTabList)
   $activeTab: WritableSignal<string> = signal('Sections')
   protected readonly adminNavigationTabList = adminNavigationTabList
}
