import { HttpResponse } from '@angular/common/http'
import { set } from 'date-fns/set'
import { PipeTransform } from '@angular/core'

export const MIME_XLSX: string =
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

// TODO: P4 - could use the filename from Content-Disposition or cleanup date format to include hh:mi:ss
// FIXME: P4 - timestamp just uses yyyy-mm-dd; files exported the same day would overwrite or duplicate
export function downloadAsExcel(response: HttpResponse<any>, fileName: string) {
   const content: Blob = new Blob([response.body] as any, {
      type: MIME_XLSX,
   })
   let downloadLink = document.createElement('a')
   downloadLink.href = URL.createObjectURL(content)
   let ts = new Date().toLocaleDateString()
   downloadLink.setAttribute('download', `${fileName}_${ts}`)
   document.body.appendChild(downloadLink)
   downloadLink.click()
   downloadLink.parentNode!.removeChild(downloadLink)
}

// dateToLocalizedMidnight accept a date-only string in the format YYYY-MM-DD
// and returns a Date object, set to the target date, localized to the current timezone
// and set to 11:59:59 PM.
// E.g. dateToLocalizedMidnight('2026-01-01') --> Wed Jan 01 2025 23:59:59 GMT-0500 (Eastern Standard Time)
export function dateToLocalizedMidnight(dateString: string): Date {
   const dateParts = dateString.split('-')
   let d = new Date()
   return set(d, {
      year: parseInt(dateParts![0]),
      month: parseInt(dateParts![1]) - 1, // Months start at 0 because of bad decisions in 1995
      date: parseInt(dateParts![2]),
      hours: 23,
      minutes: 59,
      seconds: 59,
   })
}

// isZeroDate returns true if the dateString represents the zero date value in Go (Mon, 01 Jan 0001 00:00:00 GMT / 0001-01-01T00:00:00Z)
// This is useful because the API frequently will return a zero date rather than null
export function isZeroDate(dateString: string): boolean {
   const goZeroDateValueUTC = 'Mon, 01 Jan 0001 00:00:00 GMT'
   return new Date(dateString).toUTCString() === goZeroDateValueUTC
}

export class ZeroDatePipePipe implements PipeTransform {
   transform(value: Date | string): null | string {
      const goZeroDateValueUTC = 'Mon, 01 Jan 0001 00:00:00 GMT'
      if (typeof value === 'string') {
         value = new Date(value)
      }

      if (value.toUTCString() === goZeroDateValueUTC) {
         return null
      } else {
         return value.toLocaleString()
      }
   }
}
