import { Component, Input, signal, WritableSignal } from '@angular/core'
import { DangerComponent } from '../button/danger/danger.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgForOf, NgIf } from '@angular/common'
import { ButtonComponent } from '../button/button.component'

@Component({
   selector: 'app-modal',
   standalone: true,
   imports: [
      DangerComponent,
      FormsModule,
      NgForOf,
      NgIf,
      ReactiveFormsModule,
      ButtonComponent,
   ],
   templateUrl: './modal.component.html',
})
export class ModalComponent {
   // Title that will be displayed at the top of the modal
   @Input({ required: true }) title: string = ''

   // A signal to communicate with the parent whether or not the modal should be displayed.
   // The form will handle hiding itself after cancellation, background click, or successful submission event
   @Input({ required: true }) $isDisplayed: WritableSignal<boolean> =
      signal(false)

   cancel() {
      this.$isDisplayed.set(false)
   }
}
