import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
   selector: 'app-floating-options-menu',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './floating-options-menu.component.html',
})
export class FloatingOptionsMenuComponent {
   @Input() isMultiSelect: boolean = true
   @Input() options: optionsMenu | undefined
   @Input() firstRowIsHeader: boolean = false
   // @Output() selectionEvent = new EventEmitter
   // @Output() deselectionEvent = new EventEmitter
}

export type optionsMenu = optionGroup[]

type optionGroup = option[]

type option = {
   key: string
   displayValue: string
   isSelected: boolean
}
