@if (dataIsLoaded) {
   <!--Background Opacity-->
   <div
      class="relative z-30"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
   >
      <div
         class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
         (click)="this.$formIsDisplayed.set(false)"
      ></div>

      <!--Modal-->
      <div
         class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all"
      >
         <div class="border-b border-gray-200 pb-1.5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">
               New Outcome
            </h3>
         </div>

         <form [formGroup]="outcomeForm">
            <div class="mt-2">
               <label
                  for="outcomeID"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Outcome</label
               >
               <select
                  formControlName="outcomeID"
                  id="outcomeID"
                  name="outcomeID"
                  class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
               >
                  <option [ngValue]="null"></option>
                  @for (option of outcomeOptions; track option.id) {
                     <option [ngValue]="option.id">{{ option.name }}</option>
                  }
               </select>
            </div>

            <div class="mt-2">
               <label
                  for="note"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Comment</label
               >
               <div class="mt-1">
                  <input
                     formControlName="note"
                     type="text"
                     name="note"
                     id="note"
                     autocomplete="off"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
               </div>
            </div>

            <!--Footer-->
            <div class="mt-2">
               <div class="flex justify-between">
                  <div class="flex flex-row space-x-1">
                     <button
                        type="button"
                        (click)="$formIsDisplayed.set(false)"
                        class="rounded-md mt-2 bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                     >
                        Cancel
                     </button>
                     @if (!isNewRecord(this.selectedCaseOutcomeID)) {
                        <app-button-danger
                           [label]="'Delete'"
                           (click)="delete()"
                        ></app-button-danger>
                     }
                  </div>
                  <button
                     type="button"
                     (click)="submit()"
                     class="float-right mt-2 inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                     Save
                  </button>
               </div>
            </div>
         </form>
      </div>
   </div>
}
