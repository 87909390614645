import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

export type SimpleList = SimpleListItem[]

export type SimpleListItem = {
   id: number
   id_string?: string
   title: string
   description?: string
   titleTooltip?: string
   propertyPairs?: { key: string; display: string; value: string }[]
   badges?: {
      title: string
      theme: BadgeTheme
   }[]
}

@Component({
   selector: 'app-simple-list',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './simple-list.component.html',
})
export class SimpleListComponent implements OnInit {
   @Input() listData: SimpleList | undefined
   @Input() sortProperty: string | undefined
   @Input() sortAscOrDesc: string = 'ASC'
   @Output()
   selectionEvent: EventEmitter<SimpleListItem> =
      new EventEmitter<SimpleListItem>()

   constructor() {}

   ngOnInit() {
      //TODO: https://reacthustle.com/blog/typescript-sort-array-of-objects-by-property
      this.listData?.sort((a, b): number => {
         if (a.title < b.title) {
            return -1
         } else if (a.title > b.title) {
            return 1
         }
         return 0
      })
   }

   mapBadgeClass(theme: BadgeTheme): string {
      switch (theme) {
         case BadgeTheme.GREEN:
            return 'bg-green-100 text-green-700'
         case BadgeTheme.YELLOW:
            return 'bg-yellow-100 text-yellow-800'
         case BadgeTheme.GRAY:
            return 'bg-gray-100 text-gray-600'
         case BadgeTheme.RED:
            return 'bg-red-100 text-red-700'
         case BadgeTheme.ORANGE:
            return 'bg-orange-100 text-orange-700'
         default:
            return ''
      }
   }
}

export enum BadgeTheme {
   GREEN = 'GREEN',
   YELLOW = 'YELLOW',
   RED = 'RED',
   GRAY = 'GRAY',
   ORANGE = 'ORANGE',
}
