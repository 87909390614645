import {
   Component,
   HostListener,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { Subscription } from 'rxjs'
import { ActionItem, CreateActionItem, User } from '../../../shared/interfaces'
import {
   CREATED,
   NotificationService,
   NotificationType,
   UPDATED,
} from '../../../services/notification.service'
import { formatDate, NgFor, NgIf } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { isNewRecord } from '../tab-failures-modes/failure-mode-form.component'
import { DangerComponent } from '../../../shared/components/button/danger/danger.component'
import { AdminService } from '../../../services/admin.service'
import { LoggingService } from '../../../services/logging.service'
import { toZonedTime } from 'date-fns-tz'
import { DateValues } from 'date-fns'
import { set } from 'date-fns/set'
import { dateToLocalizedMidnight, isZeroDate } from '../../../shared/functions'

@Component({
   selector: 'app-action-item-form',
   templateUrl: './action-item-form.component.html',
   standalone: true,
   imports: [NgIf, ReactiveFormsModule, NgFor, DangerComponent],
})
export class ActionItemFormComponent implements OnInit {
   constructor(
      private caseManagementService: CaseManagementService,
      private adminService: AdminService,
      private notificationService: NotificationService,
      private logger: LoggingService
   ) {}
   @HostListener('window:keydown.esc', ['$event'])
   handleEscapeKey() {
      this.cancel()
   }
   @HostListener('window:keydown.enter', ['$event'])
   handleEnterKey() {
      this.submit()
   }

   // MARK: Properties
   @Input() caseID: number = 0
   @Input() selectedActionItemID: number = 0
   @Input() $caseActionItems: WritableSignal<ActionItem[]> | undefined
   @Input() $formIsDisplayed: WritableSignal<boolean> = signal(false)
   dataIsLoaded = false

   actionItemForm = new FormGroup({
      title: new FormControl('', {
         nonNullable: true,
         validators: [Validators.required],
      }),
      statusID: new FormControl(
         DEFAULT_ACTION_ITEM_STATUS,
         Validators.required
      ),
      dueDate: new FormControl('', Validators.required),
      closeDate: new FormControl(''),
      newAssignee: new FormControl(),
   })

   formChangesSubscription: Subscription = new Subscription()
   displayOptions = {
      closeDateHidden: true,
      assigneeDropdownVisible: false,
   }

   // TODO: Should be from Capabilities API
   statusOptions = [
      { id: 1, name: 'In Progress' },
      { id: 2, name: 'On Hold' },
      { id: 3, name: 'Complete' },
      { id: 4, name: 'Canceled' },
   ]

   potentialAssignees: User[] = []
   actualAssignees: User[] = []

   // MARK: Lifecycle
   ngOnInit() {
      // This may not be the most performant because every keystroke in the action item title will result in a change event
      // Check if status change necessitates showing the closeDate
      this.formChangesSubscription = this.actionItemForm.valueChanges.subscribe(
         {
            next: (value) => {
               let id = value.statusID
               this.displayOptions.closeDateHidden = id == 3 || id == 4
            },
         }
      )

      // If user requested to edit an existing action Item, fetch it into form from the API
      // TODO: Should forkJoin all of the subscribers
      if (!isNewRecord(this.selectedActionItemID)) {
         this.fetchActionItem()
      } else {
         this.dataIsLoaded = true
      }

      this.fetchPotentialAssigneeList()
   }

   fetchActionItem() {
      this.caseManagementService
         .getActionItem(this.caseID, this.selectedActionItemID)
         .subscribe({
            next: (value) => {
               this.actionItemForm.patchValue({
                  title: value.title,
                  statusID: value.statusID,
                  dueDate: formatDate(value.dueDate, 'yyyy-MM-dd', 'en-US'),
               })
               if (value.assignees !== null) {
                  this.actualAssignees = value.assignees
               }
               if (!isZeroDate(value.closeDate)) {
                  this.actionItemForm.patchValue({
                     closeDate: formatDate(
                        value.closeDate,
                        'yyyy-MM-dd',
                        'en-US'
                     ),
                  })
               }
               this.dataIsLoaded = true
            },
         })
   }

   // MARK: Logic f(x)
   submit() {
      if (isNewRecord(this.selectedActionItemID)) {
         this.createActionItem()
      } else {
         this.updateActionItem()
      }
   }

   formValuesToActionItem(): CreateActionItem {
      return {
         caseID: this.caseID.toString(),
         title: this.actionItemForm.controls['title'].getRawValue(),
         statusID: +this.actionItemForm.controls['statusID'].getRawValue()!,
         dueDate: dateToLocalizedMidnight(
            this.actionItemForm.controls.dueDate.value!
         ).toISOString(),
         closeDate: this.actionItemForm.controls.closeDate.value
            ? dateToLocalizedMidnight(
                 this.actionItemForm.controls.closeDate.value
              ).toISOString()
            : null,
         assigneeIDs: this.actualAssignees
            ? this.actualAssignees.map((a) => a.userID)
            : null,
      }
   }

   createActionItem() {
      this.caseManagementService
         .createActionItem(this.formValuesToActionItem())
         .subscribe({
            next: (actionItem) => {
               this.$caseActionItems?.update((list) => [...list, actionItem])
            },
            complete: () => {
               this.notificationService.new(NotificationType.OK, CREATED)
               this.$formIsDisplayed.set(false)
            },
         })
   }

   updateActionItem() {
      console.log('updating')
      this.caseManagementService
         .updateActionItem(
            this.caseID,
            this.selectedActionItemID,
            this.formValuesToActionItem()
         )
         .subscribe({
            next: (updatedActionItem) => {
               this.$caseActionItems?.update((items) =>
                  items.map((item) =>
                     item.actionItemID === this.selectedActionItemID
                        ? updatedActionItem
                        : item
                  )
               )
            },
            complete: () => {
               this.notificationService.new(NotificationType.OK, UPDATED)
               this.$formIsDisplayed.set(false)
            },
         })
   }

   cancel() {
      this.$formIsDisplayed.set(false)
   }

   delete() {
      this.caseManagementService
         .deleteActionItem(this.caseID, this.selectedActionItemID)
         .subscribe({
            next: () => {
               const index = this.$caseActionItems!().findIndex(
                  (actionItem) =>
                     actionItem.actionItemID === this.selectedActionItemID
               )
               if (index > -1) {
                  this.$caseActionItems!.update(() =>
                     this.$caseActionItems!().toSpliced(index, 1)
                  )
               }
            },
            complete: () => {
               this.$formIsDisplayed.set(false)
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully deleted'
               )
            },
         })
   }

   // Adds an Assignee to the actualAssignees array.
   // Note that an assignee isn't actually added until the form is submitted
   // Only the user ID is required for subsequent processing, but additional fields are accepted for completeness
   addAssignee() {
      let assignee = this.actionItemForm.controls['newAssignee'].value
      if (assignee == undefined) {
         // The null option will be undefined
         return
      }
      this.actualAssignees.push(assignee)
      // this.potentialAssignees.filter(item => item !== assignee) // Remove the selection from the list of options
      this.actionItemForm.controls['newAssignee'].reset()
   }

   removeAssignee(user: User) {
      this.actualAssignees = this.actualAssignees.filter(
         (item) => item !== user
      )
      console.log(this.actualAssignees)
   }

   fetchPotentialAssigneeList() {
      this.adminService.getManyUsers().subscribe({
         next: (value) => {
            this.potentialAssignees = value.slice()
         },
         complete: () =>
            console.log(
               'the list of potential assignes after fetching is',
               this.potentialAssignees
            ),
      })
   }

   protected readonly isNewRecord = isNewRecord
}

const DEFAULT_ACTION_ITEM_STATUS = 1
