import {
   Component,
   Input,
   OnInit,
   inject,
   signal,
   WritableSignal,
} from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { InputTextComponent } from '../../../shared/components/controls/input-text/input-text.component'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'
import { SearchResultEncounter } from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { FhirService } from '../../../services/fhir.service'
import { CaseManagementService } from '../../../services/case-management.service'
import { ModalComponent } from '../../../shared/components/modal/modal.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { encounterSearchResultColumns } from '../../../shared/tableColumnConfiguration'

@Component({
   selector: 'app-linked-encounter-form',
   standalone: true,
   imports: [
      InputTextComponent,
      ReactiveFormsModule,
      InteractiveTableComponent,
      ModalComponent,
      ButtonComponent,
   ],
   templateUrl: './linked-encounter-form.component.html',
})
export class LinkedEncounterFormComponent implements OnInit {
   @Input({ required: true }) patientFhirID: string = ''
   @Input({ required: true }) caseID: number = 0
   @Input({ required: true }) $formIsDisplayed: WritableSignal<boolean> =
      signal(false)

   fhirService = inject(FhirService)
   caseService = inject(CaseManagementService)
   notificationService = inject(NotificationService)

   encounterSearchResults: SearchResultEncounter[] = []
   encounterSearchResultColumns = encounterSearchResultColumns
   searchInProgress = false
   selectedEncounterFhirID = ''

   ngOnInit() {
      this.searchForEncounter()
   }

   searchForEncounter() {
      this.searchInProgress = true
      this.encounterSearchResults = []

      this.fhirService.listEncounters(this.patientFhirID).subscribe({
         next: (value) => {
            if (value !== null) {
               this.encounterSearchResults = value.slice(0)
            } else {
               // this.encountersNotFound = true
            }
         },
         error: (err) =>
            this.notificationService.new(
               NotificationType.Error,
               'An error occurred',
               err
            ),
         complete: () => (this.searchInProgress = false),
      })
   }

   create() {
      this.caseService
         .linkEncounterToCase(this.caseID, this.selectedEncounterFhirID)
         .subscribe({
            next: (value) => console.log(value),
            complete: () => this.$formIsDisplayed.set(false),
         })
   }

   protected readonly signal = signal
}
