import { Component, inject, Input, signal, WritableSignal } from '@angular/core'
import { ModalComponent } from '../../../shared/components/modal/modal.component'
import { FormsModule } from '@angular/forms'
import { FhirService } from '../../../services/fhir.service'
import {
   CaseLinkedPractitioner,
   InteractiveTableColumn,
   Practitioner,
} from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { CaseManagementService } from '../../../services/case-management.service'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'

@Component({
   selector: 'app-linked-staff-form',
   standalone: true,
   imports: [ModalComponent, FormsModule, InteractiveTableComponent],
   templateUrl: './linked-staff-form.component.html',
})
export class LinkedStaffFormComponent {
   @Input({ required: true }) caseID: number = 0
   @Input() $caseLinkedStaff:
      | WritableSignal<CaseLinkedPractitioner[]>
      | undefined
   @Input({ required: true }) $formIsDisplayed: WritableSignal<boolean> =
      signal(false)
   @Input() selectedLinkedStaffID: number = 0

   searchTerm = ''
   searchResults: Practitioner[] = []
   caseService = inject(CaseManagementService)
   notificationService = inject(NotificationService)
   $searchIsProcessing: WritableSignal<boolean> = signal(false)
   tableColumns: InteractiveTableColumn[] = [
      {
         key: 'fullName',
         displayValue: 'Full Name',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
      },
   ]
   fhirService = inject(FhirService)
   selectedPractitioner: Practitioner | undefined

   search() {
      this.$searchIsProcessing.set(true)
      this.fhirService.listPractitioners(this.searchTerm).subscribe({
         next: (value) => (this.searchResults = value.slice(0)),
         error: (err) => console.log(err),
         complete: () => this.$searchIsProcessing.set(false),
      })
   }

   save() {
      this.caseService
         .linkPractitionerToCase(this.caseID, this.selectedPractitioner!.fhirID)
         .subscribe({
            next: (value) =>
               this.$caseLinkedStaff?.update((list) => [...list, value]),
            error: (err) =>
               this.notificationService.new(
                  NotificationType.Error,
                  'Something went wrong',
                  err
               ),
            complete: () => this.$formIsDisplayed.set(false),
         })
   }
}
