<div id="encounterAndSurgeries">
   <div class="flex space-x-8">
      <div class="w-full">
         <!--Section Header-->
         <div class="flex justify-between py-2 border-b border-gray-100">
            <!--Title-->
            <div class="flex items-center">
               <p
                  class="text-base font-semibold leading-6 text-gray-900 align-middle"
               >
                  Linked Encounters
               </p>
            </div>

            @if (allowEditing) {
               <button
                  type="button"
                  (click)="$formIsDisplayed.set(true)"
                  class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                  Add Encounter
               </button>
            }
         </div>

         <div class="flex flex-row space-x-4">
            @if ($formIsDisplayed()) {
               <div class="w-full">
                  <app-linked-encounter-form
                     [patientFhirID]="patientFhirID"
                     [caseID]="caseID"
                     [$formIsDisplayed]="$formIsDisplayed"
                  ></app-linked-encounter-form>
               </div>
            } @else {
               <div class="w-full">
                  <app-simple-list
                     [listData]="linkedEncounterDisplayList"
                  ></app-simple-list>
               </div>
            }
         </div>
      </div>
   </div>
</div>
