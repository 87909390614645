import { Component, EventEmitter, Output } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { CaseManagementService } from '../../services/case-management.service'
import { UserCredentials } from '../../shared/interfaces'
import { AfterViewInit } from '@angular/core'
import { Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { NgIf } from '@angular/common'
import { UserService } from '../../services/user.service'

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   standalone: true,
   imports: [ReactiveFormsModule, NgIf],
})
export class LoginComponent implements AfterViewInit {
   constructor(
      private renderer: Renderer2,
      private userService: UserService
   ) {}

   // @Output() loginEvent = new EventEmitter<boolean>()
   displayedErrorMessage: string = ''

   ngAfterViewInit() {
      this.renderer.selectRootElement('#email').focus()
   }

   loginGroup = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
   })

   login() {
      this.displayedErrorMessage = ''
      let credentials = this.loginGroup.getRawValue() as UserCredentials
      this.userService.login(credentials)
   }
}
