<div class="overflow-scroll">
   <table class="min-w-full border border-spacing-0 divide-y divide-gray-300">
      <thead>
         <tr class="divide-x divide-gray-200">
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               User ID
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Name
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Email
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Tenant Roles
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Account Status
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Created
            </th>
         </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
         @for (user of $userList(); track $index) {
            <tr class="divide-x divide-gray-200">
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
               >
                  {{ user.userID }}
               </td>
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
               >
                  {{ user.name }}
               </td>
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
               >
                  {{ user.email }}
               </td>
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-center"
               >
                  @if (user.isInstanceAdmin) {
                     <app-label text="Administrator" color="blue"></app-label>
                  }
                  <!--{{ user.isInstanceAdmin }}-->
               </td>
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-center"
               >
                  <app-label text="Active" color="green"></app-label>
               </td>
               <td
                  class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
               >
                  {{ user.createdAt | zeroDatePipe | date }}
               </td>
            </tr>
         }
      </tbody>
   </table>
</div>
