<div id="documents">
   <!--Header-->
   <div class="flex justify-between py-2 border-b border-gray-100">
      <div class="flex items-center">
         <p
            class="text-base font-semibold leading-6 text-gray-900 align-middle"
         >
            Documents
         </p>
      </div>
   </div>
   <!--Content-->
   <div class="flex flex-row justify-between">
      <!--Left: Table-->
      <div class="w-full mr-16">
         <app-simple-list
            [listData]="documentDisplayList"
            (selectionEvent)="downloadDocument($event.id_string!)"
         >
         </app-simple-list>
      </div>

      <!--Right: Dropzone / Upload-->
      @if (allowEditing) {
         <div class="w-1/2 mt-4">
            <app-file-upload
               [caseID]="caseID"
               (uploadedFileEmitter)="processDocumentUpload($event)"
            ></app-file-upload>
         </div>
      }
   </div>
</div>
