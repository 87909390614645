import { inject, Injectable } from '@angular/core'
import {
   TokenBundle,
   ClinicalSection,
   UserCredentials,
   User,
   AccountActivation,
} from '../shared/interfaces'
import { CaseManagementService } from './case-management.service'
import { LoggingService } from './logging.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
// UserService describes the current user.
export class UserService {
   endpoint = inject(ConfigurationService).getAuthorizationEndpoint()
   apiRoot = inject(ConfigurationService).getEndpoint()

   constructor(
      private logger: LoggingService,
      private router: Router,
      private http: HttpClient
   ) {}

   user: User | undefined

   getAuthorizationToken(
      credentials: UserCredentials
   ): Observable<TokenBundle> {
      return this.http.post<TokenBundle>(
         `${this.endpoint}/auth/token`,
         JSON.stringify(credentials)
      )
   }

   login(credentials: UserCredentials) {
      this.getAuthorizationToken(credentials).subscribe({
         next: (token) => {
            this.user = token.authenticatedUser
            localStorage.setItem('monarchAccessToken', token.accessToken)
            localStorage.setItem(
               'monarchAccessTokenExpiry',
               token.accessTokenExpiry.toString()
            )
            localStorage.setItem('userProfile', JSON.stringify(this.user))
            localStorage.setItem('monarchRefreshToken', token.refreshToken)
            localStorage.setItem(
               'monarchRefreshTokenExpiry',
               token.refreshTokenExpiry.toString()
            )
            this.router.navigate(['home']) // TODO: prior page
         },
      })
   }

   activateAccount(input: AccountActivation): Observable<any> {
      return this.http.post<AccountActivation>(
         `${this.endpoint}/auth/activateAccount`,
         JSON.stringify(input)
      )
   }

   logout() {
      localStorage.removeItem('monarchAccessToken')
      localStorage.removeItem('monarchAccessTokenExpiry')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('monarchRefreshToken')
      localStorage.removeItem('monarchRefreshTokenExpiry')
   }

   // TODO: Should check the refresh token instead
   isLoggedIn(): boolean {
      let evaluationInstant: Date = new Date()
      let expiry: string | null = localStorage.getItem(
         'monarchAccessTokenExpiry'
      )
      // Check for Falsy value / unable to retrieve from localStorage
      if (!expiry) {
         return false
      } else {
         let expirationInstant = new Date(Date.parse(expiry))
         return expirationInstant > evaluationInstant
      }
   }

   getAccessToken(): string | null {
      return localStorage.getItem('monarchAccessToken')
   }

   getRefreshToken(): string | null {
      return localStorage.getItem('monarchRefreshToken')
   }

   getAuthenticatedUser(): User {
      return JSON.parse(localStorage.getItem('userProfile')!)
   }

   getAccessibleSections(): ClinicalSection[] {
      let s = this.getAuthenticatedUser().accessibleSections
      if (s === null) {
         return []
      } else {
         return s
      }
   }

   createUser(email: string, name: string): Observable<User> {
      console.log('creating user')
      return this.http.post<User>(
         `${this.apiRoot}/user`,
         JSON.stringify({
            email: email,
            name: name,
         })
      )
   }
}
