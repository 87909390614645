<div id="outcomesTab">
   <div class="flex space-x-8">
      <div class="w-full">
         <!--Section Header-->
         <div class="flex justify-between py-2 border-b border-gray-100">
            <!--Title-->
            <div class="flex items-center">
               <p
                  class="text-base font-semibold leading-6 text-gray-900 align-middle"
               >
                  Outcomes
               </p>
            </div>

            <!--Button Group-->
            @if (allowEditing) {
               <button
                  type="button"
                  (click)="$outcomeFormIsDisplayed.set(true)"
                  class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                  New Outcome
               </button>
            }
         </div>

         <div class="flex flex-row space-x-4">
            <!--Outcome List-->
            <div class="w-full">
               <app-simple-list
                  [listData]="caseOutcomesDisplayList"
                  (selectionEvent)="
                     selectedCaseOutcomeID = $event.id;
                     $outcomeFormIsDisplayed.set(true)
                  "
               ></app-simple-list>
            </div>

            @if ($outcomeFormIsDisplayed()) {
               <app-outcome-form
                  [caseID]="caseID"
                  [$caseOutcomes]="$caseOutcomes"
                  [$formIsDisplayed]="$outcomeFormIsDisplayed"
                  [selectedCaseOutcomeID]="selectedCaseOutcomeID"
                  class="w-1/2"
               ></app-outcome-form>
            }
         </div>
      </div>
   </div>
</div>
