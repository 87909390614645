<div class="flex flex-col">
   <app-action-tab>
      <h3
         class="ngc-header-region font-semibold text-base text-gray-700 mr-4 py-1.5"
      >
         Level of Preventability
      </h3>
      <app-interactive-table
         [columnConfiguration]="standardizedColumns"
         [tableData]="levelOfPreventabilityOptions"
         [isProcessing]="$levelOfPreventabilityIsProcessing"
         class="ngc-table-region"
      ></app-interactive-table>
   </app-action-tab>

   <app-action-tab>
      <h3
         class="ngc-header-region font-semibold text-base text-gray-700 mr-4 py-1.5"
      >
         Change in Clinical Status
      </h3>
      <app-interactive-table
         [columnConfiguration]="standardizedColumns"
         [tableData]="changeInClinicalStatusOptions"
         [isProcessing]="$changeInClinicalStatusIsProcessing"
         class="ngc-table-region"
      ></app-interactive-table>
   </app-action-tab>

   <app-action-tab>
      <h3
         class="ngc-header-region font-semibold text-base text-gray-700 mr-4 py-1.5"
      >
         Categorization
      </h3>
      <app-interactive-table
         [columnConfiguration]="standardizedColumns"
         [tableData]="categorizationOptions"
         [isProcessing]="$categorizationIsProcessing"
         class="ngc-table-region"
      ></app-interactive-table>
   </app-action-tab>
</div>
