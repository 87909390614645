<div id="failureModesTab">
   <div class="flex space-x-8">
      <div class="w-full">
         <!--Section Header-->
         <div class="flex justify-between py-2 border-b border-gray-100">
            <!--Title-->
            <div class="flex items-center">
               <p
                  class="text-base font-semibold leading-6 text-gray-900 align-middle"
               >
                  Failure Modes
               </p>
            </div>

            <!--Button Group-->
            @if (allowEditing) {
               <button
                  type="button"
                  (click)="
                     selectedCaseFailureID = 0;
                     $failureModeFormIsDisplayed.set(true)
                  "
                  class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                  New Failure Mode
               </button>
            }
         </div>

         <div class="flex flex-row">
            <app-simple-list
               [listData]="caseFailuresDisplayList"
               (selectionEvent)="
                  selectedCaseFailureID = $event.id;
                  $failureModeFormIsDisplayed.set(true)
               "
               class="w-full mr-2"
            ></app-simple-list>
            @if ($failureModeFormIsDisplayed()) {
               <app-failure-mode
                  [caseID]="caseID"
                  [$caseFailures]="$caseFailures"
                  [$formIsDisplayed]="$failureModeFormIsDisplayed"
                  [selectedCaseFailureID]="selectedCaseFailureID"
                  class="w-1/2"
               >
               </app-failure-mode>
            }
         </div>
      </div>
   </div>
</div>
