<div class="w-full">
   <ul role="list" class="divide-y divide-gray-100">
      @for (element of listData; track element.id) {
         <li
            class="flex items-center justify-between gap-x-6 py-5 cursor-pointer"
            (click)="selectionEvent.emit(element)"
         >
            <!--Main Content; Left-Side-->
            <div class="min-w-0">
               <div class="flex flex-col items-start gap-x-3">
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                     {{ element.title }}
                  </p>
                  @if (element.description) {
                     <p class="mt-1 text-sm">{{ element.description }}</p>
                  }
                  @if (element.propertyPairs) {
                     <div
                        class="mt-1 flex items-center gap-x-1 text-xs leading-5 text-gray-500"
                     >
                        @for (kvp of element.propertyPairs; track $index) {
                           <span>{{ kvp.display }}</span>
                           <span class="font-semibold">{{ kvp.value }}</span>

                           <!--Add a dot between KPVs, but not a trailing dot-->
                           @if (!$last) {
                              <span
                                 ><svg
                                    viewBox="0 0 2 2"
                                    class="h-0.5 w-0.5 fill-current"
                                 >
                                    <circle cx="1" cy="1" r="1" /></svg
                              ></span>
                           }
                        }
                     </div>
                  }
               </div>
            </div>

            <!--Badges and Actions; Right-side-->
            <div class="flex flex-row-reverse flex-none items-center gap-x-2">
               @for (badge of element.badges; track $index) {
                  <span
                     class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
                     [ngClass]="mapBadgeClass(badge.theme)"
                     >{{ badge.title }}</span
                  >
               }
            </div>
         </li>
      }
   </ul>
</div>
