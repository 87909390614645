import { Component, Input } from '@angular/core'

@Component({
   selector: 'app-no-data-found',
   standalone: true,
   imports: [],
   templateUrl: './no-data-found.component.html',
})
export class NoDataFoundComponent {
   @Input() title: string = 'No Data Found'
   @Input() subTitle: string = ''
   @Input() buttonProps: { isVisible: boolean; label: string; route: string } =
      { isVisible: false, label: '', route: '' }
}
