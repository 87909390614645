<!--Tabbed Navigation-->
<div>
   <div class="border-b border-gray-200">
      <nav class="flex flex-wrap pb-3 pt-2" aria-label="Tabs">
         @for (tab of Tabs; track $index) {
            <a
               [ngClass]="
                  tab === ActiveTab()
                     ? 'bg-gray-100 text-indigo-600'
                     : 'text-gray-800 hover:bg-gray-50 hover:text-gray-900'
               "
               (click)="ActiveTab.set(tab)"
               class="block rounded-md py-2 px-3 font-medium text-sm text-gray-500"
               >{{ tab }}
            </a>
         }
      </nav>
   </div>
</div>
