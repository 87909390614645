import {
   Component,
   inject,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { FhirService } from '../../../services/fhir.service'
import {
   InteractiveTableColumn,
   SearchResultProcedure,
} from '../../../shared/interfaces'
import { ModalComponent } from '../../../shared/components/modal/modal.component'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'

@Component({
   selector: 'app-linked-procedure-form',
   standalone: true,
   imports: [ModalComponent, InteractiveTableComponent],
   templateUrl: './linked-procedure-form.component.html',
})
export class LinkedProcedureFormComponent implements OnInit {
   @Input({ required: true }) patientFhirID: string = ''
   @Input({ required: true }) $formIsDisplayed: WritableSignal<boolean> =
      signal(false)
   fhirService = inject(FhirService)
   $isProcessing: WritableSignal<boolean> = signal(false)
   selectableProcedures: SearchResultProcedure[] = []
   ngOnInit() {
      this.$isProcessing.set(true)
      this.fhirService.listProcedures(this.patientFhirID).subscribe({
         next: (value) => (this.selectableProcedures = value.slice(0)),
         complete: () => this.$isProcessing.set(false),
      })
   }

   procedureSearchResultColumns: InteractiveTableColumn[] = [
      {
         key: 'performedAt',
         sortIndex: 0,
         displayValue: 'Performed On',
         dataType: 'date',
         isVisible: true,
      },
      {
         key: 'name',
         sortIndex: 1,
         displayValue: 'Name',
         dataType: 'string',
         isVisible: true,
      },
      {
         key: 'performedBy',
         sortIndex: 2,
         displayValue: 'By',
         dataType: 'string',
         isVisible: true,
      },
      {
         key: 'status',
         sortIndex: 3,
         displayValue: 'Status',
         dataType: 'string',
         isVisible: true,
      },
   ]
}
